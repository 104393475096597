import { EpubCFI } from "epubjs";
import { eq } from "./CommonUtils";
import { isTextNode, isElementNode, isEmptyElement } from "./DomUtils";

const EMPTY_TERMINAL = { offset: null, assertion: null};

/**
 * Converts a Range into a CFI, properly accounting for boundaries in element containers.
 * @param {Range} range 
 * @param {*} base 
 * @param {string} ignoreClass 
 * @returns {string}
 */
export function cfiFromRange(range, base, ignoreClass) {

    // Attempt to adjust the endpoints to be epubcfi-friendly.
    const r = adjustRangeEndpoints(range);

    const cfi = new EpubCFI(r, base, ignoreClass);

    const endPoints = [];

    if (cfi.range) {
        endPoints.push([r.startContainer, r.startOffset, cfi.start]);
        endPoints.push([r.endContainer, r.endOffset, cfi.end]);
    } else {
        endPoints.push([r.endContainer, r.endOffset, cfi.path]);
    }

    endPoints.forEach((e) => {
        const container = e[0];
        const offset = e[1];
        const path = e[2];

        if (isElementNode(container)) {
            // At this point, only empty elements could end up with an element node container.
            const node = container.childNodes[offset];
    
            // Pop off the faux text node, push this one in.
            path.steps.pop();
            path.steps.push(step(node));
            path.terminal = EMPTY_TERMINAL;
        }
    });

    const adjustedCfi = cfi.toString();
    return adjustedCfi;
}

/**
 * Patches Ranges from Rendition.getRange() that point to a zero child of a childless element.
 * @param {Range} range 
 */
export function patchRange(range) {
    if (range) {
        const patchedRange = range.cloneRange();

        if (isEmptyElement(patchedRange.startContainer) && patchedRange.startOffset === 0) {
            patchedRange.setStartBefore(patchedRange.startContainer);
        }

        if (isEmptyElement(patchedRange.endContainer) && patchedRange.endOffset === 0) {
            patchedRange.setEndBefore(patchedRange.endContainer);
        }

        return patchedRange;
    }
    return range;
}

/**
 * Given two CFIs, generates a CFI range that encompasses the earliest and latest
 * points from both CFIs. Endpoints a and b do not need to be provided in document order,
 * and they may be single locations or ranges.
 * @param {string} a CFI endpoint a
 * @param {string} b CFI endpoint b
 * @return {string} CFI string if there is a legal range between the two CFIs.
 */
export function cfiRangeFromCfiPair(a, b) {
    // Return value
    const cfi = new EpubCFI();

    // Determine the endpoints.
    const aStart = new EpubCFI(a);
    const bStart = new EpubCFI(b);
    const aEnd = new EpubCFI(a);
    const bEnd = new EpubCFI(b);

    // Bail if the CFIs aren't even from the same base document.
    if (!eq(aStart.base, bStart.base)) {
        return null;
    }

    // Collapse to starts and ends for comparison. The collapse() function mutates the CFIs.
    aStart.collapse(true);
    bStart.collapse(true);
    aEnd.collapse();
    bEnd.collapse();

    // Pick the earliest start point and the latest end point.
    const start = (cfi.compare(aStart, bStart) < 0) ? aStart : bStart;
    const end = (cfi.compare(aEnd, bEnd) > 0) ? aEnd : bEnd;

    // Initialize the new cfi
    cfi.base = Object.assign({}, start.base);
    cfi.path.steps = [];
    cfi.path.terminal = EMPTY_TERMINAL;
    cfi.range = true;

    // Accumulate shared steps. Break when they stop matching OR if we encounter a shared text step.
    let i = 0;
    while (i < start.path.steps.length) {
        if (cfi.equalStep(start.path.steps[i], end.path.steps[i]) && (start.path.steps[i].type !== 'text')) {
            cfi.path.steps.push(start.path.steps[i]);
            i++;
        } else {
            break;
        }
    }

    // Place remainders into start/end paths
    cfi.start = {
        steps: start.path.steps.slice(i),
        terminal: start.path.terminal
    };

    cfi.end = {
        steps: end.path.steps.slice(i),
        terminal: end.path.terminal
    };

    return cfi.toString();
}

/**
 * Given a CFI range, return an object containing pair of CFIs corresponding to the
 * range's endpoints. If given a non-range CFI, both start and end will be the same CFI.
 * @param {string} cfi CFI string
 * @returns {{start: string, end: string}} Simple object containing start/end CFIs
 */
export function cfiPairFromCfiRange(cfi) {

    // Make some new CFI objects
    const start = new EpubCFI(cfi);
    const end = new EpubCFI(cfi);

    if (start.range) {
        start.collapse(true);
        end.collapse();
    }

    return {
        start: start.toString(),
        end: end.toString()
    };
}

/**
 * Creates a CFI step-shaped object from the provided Element.
 * @param {Element} element
 */
function step(element) {

    return {
        id: element.id,
        tagName: element.tagName,
        type: 'element',
        index: [...element.parentElement.children].indexOf(element)
    };
}

/**
 * Finds the next text or empty (childless element) node that follows this one in the DOM.
 * @param {Node} node
 * @returns {Node} The next text or empty node in DOM order, or null if none could be found.
 */
 function findNextTextOrEmptyNodeInDomOrder(node) {

    // Check the most likely suspect first, to avoid unnecessary TreeWalker creation.
    if (node.hasChildNodes() && (isTextNode(node.firstChild) || isEmptyElement(node.firstChild))) {
        return node.firstChild;
    } else {
        const walker = createDomWalker(node);
        walker.currentNode = node;
        return walker.nextNode();
    }
}

/**
 * Finds the closest preceding text or empty (childless element) node, .
 * @param {Node} node
 * @returns {Node} The closest text or empty node that precedes this one in  DOM order, null if none is found.
 */
 function findNextTextOrEmptyNodeInReverseDomOrder(node) {

    // Check the most likely suspect first, to avoid unnecessary TreeWalker creation.
    // Drill down to the deepest lastChild. DOM order is element, then contents, so when going
    // backwards we need to start from the node's contents.
    let walkerOrigin = node;
    while (walkerOrigin.hasChildNodes()) {
        walkerOrigin = walkerOrigin.lastChild;
    }
    
    if (isTextNode(walkerOrigin) || isElementNode(walkerOrigin)) {
        return walkerOrigin;
    } else {
        const walker = createDomWalker(node);
        walker.currentNode = walkerOrigin;
        return walker.previousNode();    
    }
}


/**
 * Adjusts range endpoints. We want round-trips from Range to CFI and back to be as consistent
 * as possible, so our first step is to adjust the endpoints so that they are as close as possible
 * to what epubcfi.js wants while still retaining the text content of the original Range.
 * For all element anchor containers:
 * - Offsets that reference a text node are converted to text node references with a zero char offset.
 * - Offsets that reference an element node with children are converted to a reference for either
 *   the next text node in DOM order with a zero char offset or empty element node.
 * - Offsets that reference an element node with no children (empty nodes) are left alone
 * - Offsets after last node child, where last node child is text, are converted to text node reference
 *   with a char offset equal to the length of the text node.
 * - Offsets after last node child, where last node child is an element, are converted to either
 *   a text node reference for the next text node in DOM order with a zero char offset or empty
 *   element node.
 * 
 * @param {Range} range 
 * @returns {Range}
 */
export function adjustRangeEndpoints(range) {

    const r = range.cloneRange();

    const endpoints = [
        [r.startContainer, r.startOffset, r.setStartBefore, r.setStart],
        [r.endContainer, r.endOffset, r.setEndBefore, r.setEnd]];

    endpoints.forEach((e) => {
        const container = e[0];
        const offset = e[1];
        const setBefore = e[2];
        const set = e[3];

        if (isElementNode(container)) {

            // find the referenced node
            let node = container.childNodes[offset];

            if (isTextNode(node)) {
                // If it's text, easy peasy. Use the 0 index.
                set.call(r, node, 0);
            } else if (isEmptyElement(node)) {
                // Empty elements are also easy
                setBefore.call(r, node);
            } else if (isElementNode(node)) {
                // Element with children, find the next available text or empty node
                node = findNextTextOrEmptyNodeInDomOrder(node);
                if (isEmptyElement(node)) {
                    setBefore.call(r, node);
                } else if (isTextNode(node)) {
                    set.call(r, node, 0);
                }
            } else {
                // Yuck, we're at a last child + 1 situation.
                if (isTextNode(container.lastChild)) {
                    // Simple case: use the end of the text node.
                    node = container.lastChild;
                } else if (isElementNode(container.lastChild)) {
                    // Tougher case, we have to search backwards for the last usable point
                    node = findNextTextOrEmptyNodeInReverseDomOrder(container.lastChild, true);
                }

                if (isTextNode(node)) {
                    set.call(r, node, node.length);
                } else if (isEmptyElement(node)) {
                    // Not ideal, we may be off by 1 empty element.
                    setBefore.call(r, node);
                }
            }
        }
    });

    return r;
}

/**
 * Creates a DOM walker for the given node's document that looks for text or empty element nodes.
 * @param {Node} node 
 * @returns {TreeWalker}
 */
function createDomWalker(node) {
    return node.ownerDocument.createTreeWalker(node.ownerDocument.body,
        NodeFilter.SHOW_TEXT | NodeFilter.SHOW_CDATA_SECTION | NodeFilter.SHOW_ELEMENT,
        (n) => (isTextNode(n) || isEmptyElement(n)) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT);
}