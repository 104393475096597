import React from 'react';
import {Col, Row, Button, Form} from 'react-bootstrap';
import {getFilteredVoices} from '../utils/SpeechUtils';
import CustomSlider from "./CustomSlider";
import './AudioTab.css';

class AudioTab extends React.Component {

    constructor(props) {
        super(props);
        // create a new synth instance
        this.synth = window.speechSynthesis;

        // filter the voices based on language
        this.voices = getFilteredVoices(props.voices, [window.navigator.language, this.props.language], 'en');
        
        // Bindings
        this.handlePlaySampleClicked = this.handlePlaySampleClicked.bind(this);

        // "Play Sample" button text
        this.sampleText = "The quick brown fox jumps over the lazy dog.";
    }

    handlePlaySampleClicked() {
        this.props.onVoiceSampleRequested && this.props.onVoiceSampleRequested();

        // create a new utterance
        const sampleUtterance = new SpeechSynthesisUtterance(this.sampleText);

        // get the voice and rate settings
        sampleUtterance.voice = this.synth.getVoices().find(f => f.voiceURI === this.props.voice);
        sampleUtterance.rate = this.props.audioSpeed;

        // voice the sample
        this.synth.speak(sampleUtterance);
    }

    render() {
        const voiceOptions = this.voices.map(f => {
            return (
                <option value={f.voiceURI} key={f.voiceURI}>
                    {f.name}
                </option>
            );
        })

        const selectedVoiceUri = (this.voices.find(f => f.voiceURI === this.props.voice) || this.voices[0])?.voiceURI;
        const voiceMenuLabel = "voiceMenuLabel";

        return (
            <>
                <Row className="voice-row">
                    <Col xs={12} sm={3}>
                        <div className="setting-header">
                            <strong id={voiceMenuLabel}>Voice</strong>
                        </div>
                    </Col>
                    <Col xs={12} sm={9} className="voice-dropdown-col">
                        <select 
                            className="voice-dropdown"
                            value={selectedVoiceUri} 
                            onChange={(evt) => this.props.onVoiceSelect(evt.target.value)}
                            aria-labelledby={voiceMenuLabel}
                        >
                            {voiceOptions}
                        </select>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <CustomSlider
                            name="audioSpeed"
                            label="Audio Speed"
                            value={this.props.audioSpeed}
                            options={this.props.audioSpeedOptions}
                            onChange={this.props.onAudioSpeedChange}
                            incrementButtonLabel={"Increase Audio Speed"}
                            decrementButtonLabel={"Decrease Audio Speed"}
                            defaultButtonLabel={"Set to Normal Speed"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{offset: 4}} className="play-sample-button-col">
                        <Button 
                            className="play-sample-button"
                            onClick={this.handlePlaySampleClicked}
                            aria-label="Play Voice Sample"
                        >
                            <Row>
                                <Col>Play Sample</Col>
                            </Row>
                        </Button>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12} sm={3}>
                        <div className="setting-header">
                            <strong>Voicing Options</strong>    
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        <Form.Check
                            type="checkbox"
                            checked={this.props.voicePageNumbers}
                            label="Voice Page Numbers"
                            onChange={(evt) => this.props.onVoicePageNumberChange(evt.target.checked)}
                            id="voice_page_numbers_checkbox"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={this.props.voiceImageDescriptions}
                            label="Voice Image Descriptions"
                            onChange={(evt) => this.props.onVoiceImageDescriptionChange(evt.target.checked)}
                            id="voice_image_descriptions_checkbox"
                        />
                    </Col>
                </Row>
                <br/>
            </>
        );
    }
}

export default AudioTab;