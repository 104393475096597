/**
 * 
 * @param {Array<SpeechSynthesisVoice>} voices 
 * @param {Array<string>} languages ISO language codes
 * @param {string} fallbackLanguage
 */
export function getFilteredVoices(voices, languages, fallbackLanguage) {
    const allVoices = new Set();
    languages.forEach(l => filterVoicesForLanguage(voices, l).forEach(v => allVoices.add(v)));
    return (allVoices.size > 0) ? [...allVoices] : filterVoicesForLanguage(voices, fallbackLanguage);
}

/**
 * 
 * @param {Array<SpeechSynthesisVoice>} voices 
 * @param {string} language ISO language code
 */
export function filterVoicesForLanguage(voices, language) {
    return voices.filter(f => f.lang === language || f.lang.startsWith(language));
}