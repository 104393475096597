import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ePub, { EpubCFI } from "epubjs";
import BookmarkRenderer from "../utils/annotator/BookmarkRenderer";
import {
  collectOverridableSelectors,
  cssRulesFromViewerSettings,
  clearStylesheet,
  injectStylesheetRules,
} from "../utils/CssUtils";
import { cfiFromRange, cfiRangeFromCfiPair } from "../utils/CfiUtils";
import Highlighter from "../utils/Highlighter.js";
import TtsPlayer from "../utils/TtsPlayer.js";
import patchRendition from "../utils/patchRendition";
import "./EpubViewer.css";
import { SearchDirection, findClosestMatchingElement, findMatchingElementInRange } from "../utils/DomUtils.js";

const EMPTY_LOCATION = {
  start: { index: 0, cfi: "" },
  atStart: true,
  atEnd: true,
};

const NS_XHTML = "http://www.w3.org/1999/xhtml";

class EpubViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.state = {
      currentLocation: Object.assign({}, EMPTY_LOCATION),
      lastViewerSettings: null,
    };

    // All of this is local bookkeeping for wrapping epub.js. We're leaving it out of React
    // component state to avoid any overhead from change detection and rerendering.
    this.book = this.rendition = null;

    // Local bookkeeping for navigation info
    this.navigation = this.navigationBaseUrl = null;

    // TTS variables
    this.highlighter = new Highlighter({
      wordHighlightColor: props.viewerSettings.wordHighlightColor,
      sentenceHighlightColor: props.viewerSettings.sentenceHighlightColor,
    });
    this.cfiComparator = new EpubCFI();
    this.cfiBase = null;

    // CSS variables
    this.sectionOverrideSelectors = {};

    this.handleArrowNextClick = this.handleArrowNextClick.bind(this);
    this.handleArrowPrevClick = this.handleArrowPrevClick.bind(this);

    this.handleRenditionRender = this.handleRenditionRender.bind(this);
    this.handleLocationUpdate = this.handleLocationUpdate.bind(this);
    this.handleContentDoubleClick = this.handleContentDoubleClick.bind(this);

    // Display sequence handling (for managing the loading spinner)
    this.handleDisplaySequenceStart =
      this.handleDisplaySequenceStart.bind(this);
    this.handleDisplaySequenceEnd = this.handleDisplaySequenceEnd.bind(this);

    // Error handling
    this.handleError = this.handleError.bind(this);

    // TTS
    this.onTtsOutOfElements = this.onTtsOutOfElements.bind(this);
    this.ttsHighlightSentence = this.ttsHighlightSentence.bind(this);
    this.ttsHighlightWord = this.ttsHighlightWord.bind(this);
    this.ttsClearSentenceHighlight = this.ttsClearSentenceHighlight.bind(this);
    this.ttsClearWordHighlight = this.ttsClearWordHighlight.bind(this);
    this.ttsUpdateViewerPosition = this.ttsUpdateViewerPosition.bind(this);
    this.ttsPlayer = new TtsPlayer({
      onSpeechEnd: () => this.props.onSpeechStateChanged(false),
      onOutOfElements: this.onTtsOutOfElements,
      highlightSentence: this.ttsHighlightSentence,
      highlightWord: this.ttsHighlightWord,
      clearSentenceHighlight: this.ttsClearSentenceHighlight,
      clearWordHighlight: this.ttsClearWordHighlight,
      updateViewerPosition: this.ttsUpdateViewerPosition,
    });

    // Hotkey handling
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    // Bookmark renderer
    this.bookmarkRenderer = new BookmarkRenderer(this);
  }

  componentDidMount() {
    console.log("EpubViewer mounted");

    // Add listener for mathjax render messages
    window.addEventListener("message", (event) => {
      if (
        event.origin === window.origin &&
        event.data === "mathjaxTypesetCompleted"
      ) {
        console.log("Mathjax typesetting complete");
      } else if (
        event.origin === window.origin &&
        event.data === "mathjaxInfoWindowOpened"
      ) {
        this.scrollMathJaxInfoWindowWithContent();
      }
    });

    // Load Book
    this.loadBook();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewerSettings !== prevProps.viewerSettings) {
      // End playback
      if (this.ttsPlaying) {
        this.ttsStop();
      }

      this.applyViewerSettings(this.props.viewerSettings);
    }

    if (
      (this.rendition?.settings.flow === "scrolled") &&
      this.viewerRef.current
    ) {
      const rootElement = this.viewerRef.current;
      if (rootElement.firstChild) {
        rootElement.firstChild.style.overflowX = "hidden";
      }
    }

    if (this.props.bookmarks !== prevProps.bookmarks) {
      this.bookmarkRenderer.drawBookmarks(this.props.bookmarks);
    }

    if (this.props.highlights !== prevProps.highlights) {
      this.renderHighlights();
    }
  }

  // TTS imperatives
  ttsStart() {
    if (this.rendition) {
      const range = this.rendition.getRange(
        this.state.currentLocation.start.cfi
      );

      // Resume TTS if it had been stopped mid-speech
      if (
        this.ttsPlayer.activeElement &&
        this.ttsPlayer.currentSentence !== -1
      ) {
        this.ttsPlayer.play(
          this.ttsPlayer.activeElement,
          this.ttsPlayer.currentSentence
        );
      } else {
        const startElement =
          range.startContainer.nodeType === Node.ELEMENT_NODE
            ? range.startContainer
            : range.startContainer.parentElement;
        this.ttsPlayer.playFromNode(startElement);
      }

      this.ttsPlaying = true;
      this.props.onSpeechStateChanged(true);
    }
  }

  onTtsOutOfElements() {
    // Call next, wait for render, wait until location is reported, then resume speaking.
    if (this.hasNext()) {
      this.ttsRelocating = true;
      this.rendition
        .next()
        .then(() => this.rendition.reportLocation())
        .then(() => this.ttsStart());
    } else {
      this.props.onSpeechStateChanged(false);
    }
  }

  ttsStop() {
    this.ttsPlayer.stop();
    this.ttsPlaying = false;
    this.props.onSpeechStateChanged(false);
  }

  ttsInterrupt() {
    this.ttsPlayer.interrupt();
    this.ttsPlaying = false;
    this.props.onSpeechStateChanged(false);
  }

  ttsSkipAhead() {
    this.ttsPlayer.ffwd();
  }

  ttsSkipBack() {
    this.ttsPlayer.rew();
  }

  ttsHighlightWord(range) {
    this.highlighter.highlightWord(
      range,
      this.props.viewerSettings.wordHighlightColor
    );
  }

  ttsHighlightSentence(range) {
    this.highlighter.highlightSentence(
      range,
      this.props.viewerSettings.sentenceHighlightColor
    );
    this.emitTtsSpeechLocation(range);
  }

  ttsClearWordHighlight() {
    this.highlighter.clearWordHighlight();
  }

  ttsClearSentenceHighlight() {
    this.highlighter.clearSentenceHighlight();
  }

  ttsUpdateViewerPosition(range) {
    // epub.js has trouble with element-based offsets. If both range endpoint offsets are
    // in element containers, then range -> CFI -> range conversion breaks. Proceed only
    // if one of the endpoints is a text node.

    if (
      range.startContainer.nodeType === Node.TEXT_NODE ||
      range.endContainer.nodeType === Node.TEXT_NODE
    ) {
      const cfi = cfiFromRange(range, this.cfiBase).toString();

      // Move if cfi is *before* the start, or *after* the end.
      if (
        this.cfiComparator.compare(
          cfi,
          this.state.currentLocation.start.cfi
        ) === -1 ||
        this.cfiComparator.compare(cfi, this.state.currentLocation.end.cfi) ===
          1
      ) {
        this.ttsRelocating = true;
        this.rendition.display(cfi).then(() => {
          this.ttsRelocating = false;
        });
      }
    }
  }

  /**
   * Signals the currently-spoken sentence to the parent component.
   * @param {Range} range
   */
  emitTtsSpeechLocation(range) {
    this.props.onSpeechLocationUpdated &&
      this.props.onSpeechLocationUpdated(range);
  }

  handleKeyDown(event) {
    this.props.hotkeyManager.handleKeyDown(event);
  }

  handleKeyUp(event) {
    this.props.hotkeyManager.handleKeyUp(event);
  }

  loadBook() {
    // destroy any existing book
    if (this.book && this.book.destroy) {
      this.book.destroy();
      this.book = this.rendition = null;
      this.setState({ currentLocation: Object.assign({}, EMPTY_LOCATION) });
    }

    // todo: pass through any existing device-level display settings
    const rootElement = this.viewerRef.current;
    const book = new ePub.Book({ requestCredentials: true });
    this.book = book;

    // Hook into book load handlers
    book.loaded.metadata.then(
      (metadata) =>
        this.props.onMetadataLoaded && this.props.onMetadataLoaded(metadata)
    );
    book.loaded.spine.then(
      (spine) => this.props.onSpineLoaded && this.props.onSpineLoaded(spine)
    );
    book.loaded.navigation.then((navigation) => {
      this.navigation = navigation;

      // Include base URL of navigation document. All spine items are relative to this path.
      const navPath = book.packaging.navPath;
      this.baseUrl =
        navPath.indexOf("/") >= 0
          ? navPath.substring(0, navPath.lastIndexOf("/") + 1)
          : "";
      this.props.onNavigationLoaded &&
        this.props.onNavigationLoaded(navigation, this.baseUrl);
      this.props.onPageListLoaded && this.props.onPageListLoaded(book.pageList);
    });
    // Open the book
    book
      .open(`/epub/${this.props.bookshareId}/`)
      .then(() => {
        this.rendition = book.renderTo(rootElement, {
          flow: (this.props.bookHasMath) ? "scrolled" : this.props.viewerSettings.flow,
          height: "100%",
          axis: "vertical",
          allowPopups: true,
          allowScriptedContent: true,
        });

        //Hook fires exactly once when the section is accessed
        this.book.spine.hooks.content.register((document, section) => {
          // Inject custom font definitions
          this.attachWebFontCss(document);

          // Inject MathJax code if needed
          if (section.properties.includes("mathml")) {
            console.log("Attaching mathjax support");
            this.attachMathjaxSupport(document);
          }
        });
        //Hook fires every time the section is accessed
        this.rendition.hooks.content.register((contents, rendition) => {
          if (!this.sectionOverrideSelectors[contents.sectionIndex]) {
            this.sectionOverrideSelectors[contents.sectionIndex] =
              collectOverridableSelectors(contents.document);
          }
          return this.applyViewerSettings(this.props.viewerSettings);
        });
        this.rendition.on("rendered", this.handleRenditionRender);
        this.rendition.on("relocated", this.handleLocationUpdate);

        // All of these events should display the spinner
        this.rendition.on("resizeRequested", this.handleDisplaySequenceStart);
        this.rendition.on("displayRequested", this.handleDisplaySequenceStart);
        this.rendition.on("nextRequested", this.handleDisplaySequenceStart);
        this.rendition.on("prevRequested", this.handleDisplaySequenceStart);

        // All of these events should dismiss the spinner
        this.rendition.on("displayed", this.handleDisplaySequenceEnd);
        this.rendition.on("nextCompleted", this.handleDisplaySequenceEnd);
        this.rendition.on("prevCompleted", this.handleDisplaySequenceEnd);

        // Catches any errors triggered by rendition.display, rendition.next, and rendition.prev calls.
        this.rendition.on("displayerror", this.handleError);
        this.rendition.on("nextError", this.handleError);
        this.rendition.on("prevError", this.handleError);

        // Watch for selection events
        this.rendition.on("selected", (cfiRange) => {
          this.props.onTextSelectionChanged &&
            this.props.onTextSelectionChanged(cfiRange);
        });

        // Apply monkey patches
        patchRendition(this.rendition);

        if (process.env.NODE_ENV !== "production") {
          window.rendition = this.rendition;
          window.epubViewer = this;
        }
      })
      // on book load we need to get the location from either the server or local storage
      // but need to do it here because the rendition needs to be created and ready first
      .then(() => this.retrieveLastLocationUpdate(this.props.bookshareId))
      .then((location) => {
        if (this.rendition) {
          return (location
            ? this.rendition.display(location, true)
            : this.rendition.display());
        }
      })
      // Make the scrollable div container focusable
      .then(() => {
        const views = this.rendition.views();
        views.container.tabIndex = 0;
        views.container.ariaLabel = "Book content";
      })

      .catch((e) => {
        this.handleError(e);
      });
  }

  /**
   * Attaches web fonts CSS to the provided document
   * @param {Document} document
   */
  attachWebFontCss(document) {
    const css = document.createElementNS(NS_XHTML, "link");
    css.setAttribute("type", "text/css");
    css.setAttribute("rel", "stylesheet");
    css.setAttribute(
      "href",
      `${process.env.PUBLIC_URL}/fonts/fontDefinitions.css`
    );
    document.head.appendChild(css);
  }

  /**
   * Attaches MathJax script and CSS to the provided document.
   * @param {Document} document
   */
  attachMathjaxSupport(document) {
    const script = document.createElementNS(NS_XHTML, "script");
    script.setAttribute("src", `${process.env.PUBLIC_URL}/scripts/Mathjax.js`);
    script.setAttribute("type", "text/javascript");
    document.head.appendChild(script);
  }

  /**
   * Locates or creates the stylesheet we will use to apply user viewer settings.
   * @param {Document} document
   * @returns {CSSStyleSheet}
   */
  findOrCreateViewerSettingsStyleSheet(document) {
    const styleId = "bks-viewer-styles";
    for (const styleSheet of document.styleSheets) {
      // TODO: Is there a better (Firefox-supported) method for detecting CSSStyleRules?
      if (styleSheet.ownerNode && styleSheet.ownerNode.id === styleId) {
        return styleSheet;
      }
    }
    const styleBlock = document.createElement("style");
    styleBlock.id = styleId;
    styleBlock.setAttribute("type", "text/css");
    document.head.appendChild(styleBlock);
    return this.findOrCreateViewerSettingsStyleSheet(document);
  }

  /**
   * Injects stylesheet rules based on ViewerSettings into the rendition's active Views.
   * @param {ViewerSettings} settings Viewer settings
   * @returns {Promise} Either the rendition.display Promise or an immediately-resolved Promise.
   */
  applyViewerSettings(settings) {
    console.log("applying settings");
    console.log(settings);

    // Default promise return value
    let promise = new Promise((resolve, reject) => resolve());

    const layoutShift =
      this.state.lastViewerSettings &&
      !settings.layoutEquals(this.state.lastViewerSettings);
    const colorChange =
      this.state.lastViewerSettings &&
      !settings.colorEquals(this.state.lastViewerSettings);

    const isFixedLayout =
      (this.props.metadata && this.props.metadata.layout === "pre-paginated") ||
      false;

    // Proceed only if rendition has been initialized
    if (this.rendition) {
      const currentLocation = this.state.currentLocation.start.cfi;
      console.log(currentLocation);

      // Flow setting is special
      if (
        this.props.bookHasMath &&
        this.rendition.settings.flow !== "scrolled"
      ) {
        //Mathjax's context menu does not work well with paginated view - we want to only allow scrolling mode for these books.
        this.enableScrollingFlow();
      } else if (isFixedLayout) {
        if (this.rendition.settings.flow !== "paginated") {
          this.rendition.spread("auto");
          this.enablePaginatedFlow();
        }
      } else if (
        this.rendition.settings.flow !== settings.flow &&
        !this.props.bookHasMath
      ) {
        switch (settings.flow) {
          case "scrolled":
            this.enableScrollingFlow();
            break;
          default:
            this.enablePaginatedFlow();
        }
      }

      // So are highlighter colors
      this.highlighter.sentenceHighlightColor = settings.sentenceHighlightColor;
      this.highlighter.wordHighlightColor = settings.wordHighlightColor;

      // Okay, speech synthesis too
      this.ttsPlayer.voice = this.props.voices.find(
        (v) => v.voiceURI === settings.voice
      );
      this.ttsPlayer.rate = settings.audioSpeed;
      this.ttsPlayer.voiceImageDescriptions = settings.voiceImageDescriptions;
      this.ttsPlayer.voicePageNumbers = settings.voicePageNumbers;

      // The rest are just CSS rules
      if (!isFixedLayout) {
        this.rendition.views().forEach((v) => {
          // Generate stylesheet rules
          const rules = cssRulesFromViewerSettings(
            settings,
            this.sectionOverrideSelectors[v.section.index]
          );

          // Delete any existing rules
          const doc = v.contents.document;
          const injectedStyles = this.findOrCreateViewerSettingsStyleSheet(doc);
          clearStylesheet(injectedStyles);

          // Insert the rules
          injectStylesheetRules(injectedStyles, rules);

          v.contents.resizeCheck();
        });

        if (layoutShift) {
          promise = this.rendition.display(
            this.state.currentLocation.start.cfi
          );
        }
      }
    }

    // Put viewer settings in state
    this.setState({ lastViewerSettings: settings });

    // Chain the bookmark redraw
    return promise.then(() => {
      if (layoutShift || colorChange) {
        this.bookmarkRenderer.redrawBookmarks(this.props.bookmarks);
      }
    });
  }

  /**
   * @param {Section} section
   * @param {View} view
   */
  handleRenditionRender(section, view) {
    console.log(`Finished render of section ${section.index}`);
    const doc = view.document;

    // Keydown listeners
    doc.documentElement.addEventListener("keydown", this.handleKeyDown, { passive: false });
    doc.documentElement.addEventListener("keyup", this.handleKeyUp, { passive: false });

    // Lame workaround for the lack of event triggering when selections are cleared
    doc.addEventListener("selectionchange", (e) => {
      const selection = e.target.getSelection();
      if (selection.rangeCount === 0 || selection.getRangeAt(0).collapsed) {
        this.props.onTextSelectionChanged &&
          this.props.onTextSelectionChanged(null);
      }
    });

    // Prevent select on double-click
    doc.addEventListener("mousedown", (e) => {
      if (e.detail > 1) {
        e.preventDefault();
      }
    });

    // Attach double-click handler to the ViewManager document
    doc.addEventListener("dblclick", this.handleContentDoubleClick);

    // Update TTS player ceiling
    this.ttsPlayer.ceiling = view.document.body;

    // Hang on to cfiBase of current section for position comparisons
    this.cfiBase = section.cfiBase;

    // Redraw bookmarks
    this.bookmarkRenderer.redrawBookmarks(this.props.bookmarks);

    // Render highlights
    this.renderHighlights();

    // Set title on iframe
    if (view.iframe) {
      view.iframe.title = this.book.packaging.metadata.title;
    }

    this.props.onBookRendered && this.props.onBookRendered(section, view);
  }

  /**
   * @param {*} newLocation
   */
  handleLocationUpdate(newLocation) {
    this.setState({ currentLocation: newLocation });

    const d = newLocation.start.displayed;

    // See if we should preload the next section
    if (this.hasNext() && d.page / d.total >= 0.8) {
      this.book.section(newLocation.start.index + 1).load();
    }

    // If TTS is _not_ playing, clear any active data to account for the change in location
    if (!this.ttsPlaying) {
      this.ttsPlayer.reset();
    }

    // Find the node containing the start of the visible content.
    const startNode = this.rendition.getRange(newLocation.start.cfi).startContainer;

    // Try to find the closest preceding page break element
    const pageBreak = findClosestMatchingElement(startNode,
      e => e.getAttribute('epub:type') === 'pagebreak' || e.getAttribute('role') === 'doc-pagebreak',
      SearchDirection.BACKWARD);

    // Extract the text, giving preference to title over aria-label
    let pageTitle = pageBreak && (pageBreak.title || pageBreak.ariaLabel);

    // Strip off anything that isn't arabic or roman numerals.
    // TODO: Revisit for multi-lingual support
    pageTitle = pageTitle?.match(/\b[0-9MDCLXVImdclxvi]+\b/)?.[0];

    // Find closest TOC item. The navigation list's hrefs are relative to the navigation
    // document, while the epub.js location object contains the full path within the publication.
    // We therefore need to normalize URLs relative to the navigation document's base URL.
    const normalizedHref = newLocation.start.href.replace(this.baseUrl, '');
    const tocMatcher = e => e.id && `${normalizedHref}#${e.id}` in this.navigation.tocByHref;

    const visibleRange = this.rendition.getRange(cfiRangeFromCfiPair(newLocation.start.cfi, newLocation.end.cfi));

    // The base fallback href is always going to be the bare normalized href.
    let tocHref = normalizedHref;

    // This condition might be tough to grok. If page === 1, we are at the top of the section.
    // If the bare section HREF is in the TOC, then this top position *is* a TOC entry, so we can stop.
    //
    // If either of those is false, we need to search for an ID that corresponds to a TOC entry, starting
    // with the displayed range, then trying a backwards search.
    if (newLocation.start.displayed.page !== 1 || !(tocHref in this.navigation.tocByHref)) {
      const tocElement = findMatchingElementInRange(visibleRange, tocMatcher)
        || findClosestMatchingElement(startNode, tocMatcher, SearchDirection.BACKWARD);

      if (tocElement) {
        tocHref += `#${tocElement.id}`;
      }
    }

    this.props.onRenditionRelocated && this.props.onRenditionRelocated(newLocation, tocHref, pageTitle);
  }

  /**
   * Fires when a display sequence is started. This can be the result of:
   * - rendition.display
   * - rendition.next
   * - rendition.prev
   */
  handleDisplaySequenceStart() {
    // If the tts is relocating (see updateViewerPosition) don't stop tts.
    if (!this.ttsRelocating) {
      this.ttsStop();
    }
    this.props.onDisplaySequenceStart && this.props.onDisplaySequenceStart();
  }

  handleDisplaySequenceEnd() {
    const contents = this.rendition.getContents();
    if (contents.length > 0) {
      this.rendition.getContents()[0].css("visibility", "visible", true);
    }
    this.props.onDisplaySequenceEnd && this.props.onDisplaySequenceEnd();
  }

  /**
   * @param {MouseEvent} event
   */
  handleContentDoubleClick(event) {
    this.ttsPlaying = true;
    this.props.onSpeechStateChanged(true);
    this.ttsPlayer.playFromMouseEvent(event);
  }

  handleError(error) {
    const onErrorRaised = this.props.onErrorRaised;
    onErrorRaised && onErrorRaised(error);
  }

  /**
   * Switch to paginated mode.
   */
  enablePaginatedFlow() {
    this.rendition.flow("paginated");
  }

  /**
   * Switch to scrolling mode.
   */
  enableScrollingFlow() {
    this.rendition.flow("scrolled");
  }

  handleArrowNextClick() {
    this.rendition.next();
  }

  handleArrowPrevClick() {
    this.rendition.prev();
  }

  /**
   * Returns true if the 'next' arrow button should be enabled.
   */
  hasNext() {
    if (this.rendition?.settings.flow === "paginated") {
      return !this.state.currentLocation.atEnd;
    } else {
      return (
        this.book &&
        this.book.spine &&
        this.state.currentLocation.start.index + 1 < this.book.spine.length
      );
    }
  }

  /**
   * Returns true if the 'previous' arrow button should be enabled.
   */
  hasPrevious() {
    if (this.rendition?.settings.flow === "paginated") {
      return !this.state.currentLocation.atStart;
    } else {
      return this.state.currentLocation.start.index > 0;
    }
  }

  /**
   * Convenience function to create new epub CFI
   * @param {Range} range
   * @returns {EpubCFI} CFI
   */
  cfiFromRange(range) {
    return cfiFromRange(range, this.cfiBase);
  }

  scrollMathJaxInfoWindowWithContent() {
    const contents = this.rendition.getContents()[0];
    const contextMenu =
      contents.document.getElementsByClassName("CtxtMenu_Info")[0];
    if (contextMenu) {
      contextMenu.style.top =
        this.rendition.manager.scrollTop +
        (this.rendition.manager.viewSettings.height -
          contextMenu.clientHeight) /
          2 +
        "px";
    }
  }

  renderHighlights() {
    // Get all the current highlight keys
    const currentKeys = Object.keys(this.rendition.annotations._annotations)
      .filter((k) => k.endsWith("highlight"))
      .map((k) => decodeURI(k.substring(0, k.lastIndexOf("highlight"))));
    const updatedKeys = this.props.highlights.map((h) => h.location);

    // Get the change sets
    const toDelete = currentKeys.filter((k) => updatedKeys.indexOf(k) === -1);
    const toAdd = updatedKeys.filter((k) => currentKeys.indexOf(k) === -1);

    // Process changes
    for (let i = 0; i < toDelete.length; i++) {
      this.rendition.annotations.remove(toDelete[i], "highlight");
    }

    for (let i = 0; i < toAdd.length; i++) {
      this.rendition.annotations.highlight(toAdd[i]);
    }
  }

  /**
   * Looks to server to grab most recent reading location and falls back to local storage if there isn't one
   * @returns {String} location
   */
  async retrieveLastLocationUpdate(bookshareId) {
    // try to parse as JSON and if can't be parsed log error and try for local location
    try {
      var location;
      // check for server location
      const requestUrl = `/locationSync/${bookshareId}`;
      const response = await fetch(requestUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const responseJson = await response.json();
      location = responseJson.location;
    } catch (error) {
      console.log(`Error retrieving last location from the server: ${error}`);
    } finally {
      if (!location) {
        const locatorRecord = JSON.parse(
          localStorage.getItem(`cfi-${bookshareId}`)
        );
        location = locatorRecord ? locatorRecord.location : null;
      }
    }
    return location;
  }

  render() {
    return (
      <>
        <div
          ref={this.viewerRef}
          className="epubViewer order-1 flex-grow-1 overflow-auto"
          style={{ backgroundColor: this.props.viewerSettings.backgroundColor }}
          role="main"
        ></div>
        <Button
          variant="link"
          className="viewerArrow viewerArrowPrev"
          disabled={!this.hasPrevious()}
          onClick={this.handleArrowPrevClick}
          aria-label="Previous"
        >
          <FontAwesomeIcon icon={faAngleLeft} size="3x" role="presentation" />
        </Button>
        <Button
          variant="link"
          className="viewerArrow viewerArrowNext"
          disabled={!this.hasNext()}
          onClick={this.handleArrowNextClick}
          aria-label="Next"
        >
          <FontAwesomeIcon icon={faAngleRight} size="3x" role="presentation" />
        </Button>
      </>
    );
  }
}

export default EpubViewer;
