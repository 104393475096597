import { DEFAULT_FONT_SIZE } from './enums';

export default class ViewerSettings {
    constructor() {
        this.fontSize = DEFAULT_FONT_SIZE;
        this.fontFamily = '';
        this.letterSpacing = '';
        this.flow = 'scrolled';
        this.margin = 'medium';
        this.lineHeight = '';
        this.displayPageNumbers = false;
        this.backgroundColor = '#fff';
        this.textColor = '#000';
        this.sentenceHighlightColor = '#ffff00';
        this.wordHighlightColor = '#f59a23';
        this.voice = '';
        this.audioSpeed = 1;
        this.voicePageNumbers = false;
        this.voiceImageDescriptions = false;
    }

    /**
     * Aliases for color
     */
    set color(color) {
        this.textColor = color;
    }

    get color() {
        return this.textColor;
    }

    /**
     * Returns true if all layout-related properties are the same
     * @param {ViewerSettings} other Other ViewerSettings object to compare
     * @returns {boolean}
     */
    layoutEquals(other) {
        return (
            this.fontSize === other.fontSize &&
            this.fontFamily === other.fontFamily &&
            this.letterSpacing === other.letterSpacing &&
            this.flow === other.flow &&
            this.margin === other.margin &&
            this.lineHeight === other.lineHeight &&
            this.displayPageNumbers === other.displayPageNumbers
        );
    }

    /**
     * Returns true if all color-related properties are the same.
     * @param {ViewerSettings} other Other ViewerSettings object to compare
     * @returns {boolean}
     */
    colorEquals(other) {
        return (
            this.textColor === other.textColor &&
            this.backgroundColor === other.backgroundColor &&
            this.wordHighlightColor === other.wordHighlightColor &&
            this.sentenceHighlightColor === other.sentenceHighlightColor
        );
    }
}
