export const SLIDER_FONT_SIZES = [
    {value: '10pt', label: '10pt'},
    {value: '12pt', label: '12pt'},
    {value: '14pt', label: '14pt', default: true},
    {value: '16pt', label: '16pt'},
    {value: '18pt', label: '18pt'},
    {value: '20pt', label: '20pt'},
    {value: '22pt', label: '22pt'},
    {value: '24pt', label: '24pt'},
    {value: '26pt', label: '26pt'},
    {value: '28pt', label: '28pt'},
    {value: '30pt', label: '30pt'},
    {value: '32pt', label: '32pt'},
    {value: '34pt', label: '34pt'},
    {value: '36pt', label: '36pt'},
    {value: '38pt', label: '38pt'},
    {value: '40pt', label: '40pt'},
    {value: '42pt', label: '42pt'},
    {value: '44pt', label: '44pt'},
    {value: '46pt', label: '46pt'},
    {value: '48pt', label: '48pt'}];

export const DEFAULT_FONT_SIZE = SLIDER_FONT_SIZES.find(s => s.default === true).value;

export const FONT_FAMILIES = [
    {value: '', label: 'Book default'},
    {value: 'Arial,Helvetica Neue,Helvetica,sans-serif', label: 'Arial'},
    {value: 'Atkinson Hyperlegible', label: 'Atkinson Hyperlegible'},
    {value: 'Comic Sans MS,Comic Sans,cursive', label: 'Comic Sans'},
    {value: 'Helvetica Neue,Helvetica,sans-serif', label: 'Helvetica'},
    {value: 'Open Dyslexic', label: 'Open Dyslexic'},
    {value: 'Open Sans,sans-serif', label: 'Open Sans'},
    {value: 'Roboto,sans-serif', label: 'Roboto'},
    {value: 'TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif', label: 'Times New Roman'},
    {value: 'Verdana,Geneva,sans-serif', label: 'Verdana'}];

export const LETTER_SPACINGS = [
    {value: '', label: 'Book default'},
    {value: '0.0625em', label: 'Wide spacing', style: {'letterSpacing': '0.0625em'}},
    {value: '0.125em', label: 'Wider spacing', style: {'letterSpacing': '0.125em'}},
    {value: '0.25em', label: 'Widest spacing', style: {'letterSpacing': '0.25em'}}];
    
export const FLOWS = [
    {value: 'paginated', label: 'Paginated'},
    {value: 'scrolled', label: 'Scrolling'}];

export const PAGE_MARGINS = [
    {value: 'small', label: 'Small'},
    {value: 'medium', label: 'Medium (default)'},
    {value: 'large', label: 'Large'}];

export const LINE_HEIGHTS = [
    {value: '', label: 'Book default'},
    {value: '1', label: '1 line'},
    {value: '1.25', label: '1.25'},
    {value: '1.5', label: '1.5'},
    {value: '2', label: '2.0'},
    {value: '2.5', label: '2.5'},
    {value: '3', label: '3.0'}];

export const COLORS = [
    {value: '#fff', label: 'White'},
    {value: '#fffdd0', label: 'Cream'},
    {value: '#f8f9a2', label: 'Lemon'},
    {value: '#ffff00', label: 'Yellow'},
    {value: '#f59a23', label: 'Orange'},
    {value: '#ff8c00', label: 'Dark Orange'},
    {value: '#c6c6c6', label: 'Light Gray'},
    {value: '#959595', label: 'Dark Gray'},
    {value: '#333333', label: 'Charcoal'},
    {value: '#000', label: 'Black'},
    {value: '#bae6f9', label: 'Light Blue'},
    {value: '#1f1fff', label: 'Blue'},
    {value: '#0000e6', label: 'Royal Blue'},
    {value: '#0603b2', label: 'Bright Blue'},
    {value: '#05037c', label: 'Navy'},
    {value: '#90ee90', label: 'Light Green'},
    {value: '#95f204', label: 'Lime'},
    {value: '#00ff00', label: 'Bright Green'},
    {value: '#00803e', label: 'Green'},
    {value: '#006631', label: 'Dark Green'},
    {value: '#5252ff', label: 'Periwinkle'},
    {value: '#8080ff', label: 'Light Purple'},
    {value: '#8400ff', label: 'Purple'}, 
    {value: '#9d0654', label: 'Berry'},
    {value: '#a00d3e', label: 'Magenta'},
    {value: '#7b4b12', label: 'Plum'}];

export const COLOR_PREVIEW_NAMES = [
    {value: 'high-contrast-light', label: 'High Contrast Black on White', shortened: 'Black on White'}, 
    {value: 'high-contrast-dark', label: 'High Contrast Lemon on Dark', shortened: 'Lemon on Black'},
    {value: 'light-sensitive-lemon', label: 'Light Sensitive Lemon', shortened: 'Black on Lemon'},
    {value: 'light-sensitive-blue', label: 'Light Sensitive Blue', shortened: 'Light Blue'},
    {value: 'color-blind-white-on-blue', label: 'Color Blind White on Blue', shortened: 'White on Blue'},
    {value: 'white-on-black', label: 'White on Black', shortened: 'White on Black'},
    {value: 'gray-scale', label: 'Gray Scale', shortened: 'Gray Scale'},
    {value: 'green-on-black', label: 'Bright Green on Black', shortened: 'Green on Black'}];

export const COLOR_PRESET_MAPPINGS = [
    {key: "high-contrast-light", 
    value: {backgroundColor: '#fff',
            textColor: '#000',
            sentenceHighlightColor: '#ffff00',
            wordHighlightColor: '#f59a23'}}, 
    {key: "high-contrast-dark", 
    value: {backgroundColor: '#333333',
            textColor: '#f8f9a2',
            sentenceHighlightColor: '#5252ff',
            wordHighlightColor: '#9d0654'}}, 
    {key: "light-sensitive-lemon", 
    value: {backgroundColor: '#f8f9a2',
            textColor: '#000',
            sentenceHighlightColor: '#bae6f9',
            wordHighlightColor: '#f59a23'}}, 
    {key: "light-sensitive-blue", 
    value: {backgroundColor: '#bae6f9',
            textColor: '#000',
            sentenceHighlightColor: '#f8f9a2',
            wordHighlightColor: '#f59a23'}}, 
    {key: "color-blind-white-on-blue", 
    value: {backgroundColor: '#05037c',
            textColor: '#fff',
            sentenceHighlightColor: '#00803e',
            wordHighlightColor: '#9d0654'}}, 
    {key: "white-on-black", 
    value: {backgroundColor: '#000',
            textColor: '#fff',
            sentenceHighlightColor: '#5252ff',
            wordHighlightColor: '#9d0654'}},
    {key: "gray-scale", 
    value: {backgroundColor: '#fff',
            textColor: '#000',
            sentenceHighlightColor: '#959595',
            wordHighlightColor: '#c6c6c6'}},
    {key: "green-on-black", 
    value: {backgroundColor: '#000',
            textColor: '#00ff00',
            sentenceHighlightColor: '#7b4b12',
            wordHighlightColor: '#0000e6'}}];

export const AUDIO_SPEEDS = [
    {label: "Slowest", value: 0.5},
    {label: "Slow", value: 0.75},
    {label: "Normal", value: 1, default: true,},
    {label: "Fast", value: 1.5},
    {label: "Faster", value: 2},
    {label: "Very Fast", value: 2.5},
    {label: "Fastest", value: 3}];

export const QUICK_FONT_SIZES = [
    {label: "Regular - Book default, normal character spacing", 
    value: {fontSize: "14pt",
            letterSpacing: ""}, 
    id: 'quickRegular'},
    {label: "Large Print - Large, wide character spacing", 
    value: {'fontSize': '28pt', 
            'letterSpacing': '0.0625em'},
    id: 'quickLarge'},
    {label: "Extra Large Print - Extra large, widest character spacing", 
    value: {'fontSize': '48pt',
            'letterSpacing': '0.25em'},
    id: 'quickXLarge'}];

export const QUICK_FONTS_FAMILIES = [
    {value: '', label: 'Book default'},
    {value: 'Atkinson Hyperlegible', label: 'Atkinson Hyperlegible', style: {'fontFamily' : 'Atkinson Hyperlegible'}},
    {value: 'Helvetica Neue,Helvetica,sans-serif', label: 'Helvetica', style: {'fontFamily' : 'Helvetica Neue,Helvetica,sans-serif'}}];

export const QUICK_PAGE_FORMATS = [
    {label: "Paginated, medium margins, book default line spacing", 
    value: {flow: "paginated",
            margin: "medium", 
            lineHeight: ""},
    id: "quickPageMed"},
    {label: "Paginated, large margins, 1.5 line spacing", 
    value: {flow: "paginated",
            margin: "large",
            lineHeight: "1.5"},
    id: "quickPageLarge"},
    {label: "Scrolling, medium margins, 2 line spacing", 
    value: {flow: "scrolled",
            margin: "medium",
            lineHeight: "2"},
    id: "quickScrollMed"}];

export const QUICK_COLOR_THEME_NAMES = [
    {value: 'high-contrast-light', label: 'High Contrast Black on White', shortened: 'Black on White'}, 
    {value: 'high-contrast-dark', label: 'High Contrast Lemon on Dark', shortened: 'Lemon on Black'},
    {value: 'light-sensitive-lemon', label: 'Light Sensitive Lemon', shortened: 'Black on Lemon'},
    {value: 'color-blind-white-on-blue', label: 'Color Blind White on Blue', shortened: 'White on Blue'}];

export const GOOGLE_AUDIO_SPEEDS = [
    {label: "Slowest", value: 0.5},
    {label: "Slow", value: 0.75},
    {label: "Normal Speed", value: 1},
    {label: "Fast", value: 1.25},
    {label: "Faster", value: 1.5},
    {label: "Very Fast", value: 1.75},
    {label: "Fastest", value: 2}];

export const AUDIO_SPEED_MAPPINGS = [
    {google: 0.5, nonGoogle: 0.5},
    {google: 0.75, nonGoogle: 0.75},
    {google: 1, nonGoogle: 1},
    {google: 1.25, nonGoogle: 1.5},
    {google: 1.5, nonGoogle: 2},
    {google: 1.75, nonGoogle: 2.5},
    {google: 2, nonGoogle: 3},
]