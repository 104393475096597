export default class HotkeyManager {

    /**
     * Creates a HotKeyManager
     * @param {Object} handlers // Object that contains handlers for all hotkeys supported in the app.  Needs to have the following properties:
     * {
            openTableOfContents: () => {},
            openListOfBookmarks: () => {},
            addBookmark: () => {},
            addHighlight: () => {},
            goBackToBookshare: () => {},
            openWhereAmI: () => {},
            goToPage: () => {},
            openSettingsPane: () => {},
            startTts: () => {},
            stopTts: () => {},
            skipAheadTts: () => {},
            goBackTts: () => {},
            scrollLeft: () => {},
            scrollRight: () => {},
            openAboutPanel: () => {}
        }
     */
    constructor(handlers) {
        this.handlers = handlers;
        this._keysDown = [];

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this._handleInput = this._handleInput.bind(this);
        this._keyIsDown = this._keyIsDown.bind(this);
        this._keysAreDown = this._keysAreDown.bind(this);
    }

    /**
     * Event handler for when a key is pressed down.  Adds the key pressed to the array of keys held down
     * @param {Event} e 
     */
    handleKeyDown(e) {
        const index = this._keysDown.indexOf(e.keyCode);
        if (index < 0) { 
            this?._keysDown.push(e.keyCode);
            this._handleInput(e);
        }
    }

    /**
     * Event handler for when a key is released.  Removes the key pressed from the array of keys held down
     * @param {*} e 
     */
    handleKeyUp(e) {
        const index = this?._keysDown.indexOf(e.keyCode);
        if (index > -1) { 
            this?._keysDown.splice(index, 1); 
        }
    }

    /**
     * Helper method to check if a combination of keys are all down
     * @param  {...keys} keyCodes 
     * @returns Boolean
     */
    _keysAreDown(...keyCodes) {
        return keyCodes.every(this._keyIsDown);
    }

    /**
     * Helper method to check if a specific key is down
     * @param {...keys} keyCode 
     * @returns Boolean
     */
    _keyIsDown(keyCode) {
        return this?._keysDown.includes(keyCode);
    }

    /**
     * Helper method to check if the Alt key or Alt and Ctrl keys are held down
     * @param {Event} e 
     * @returns Boolean
     */
    _modifierKeysDown(e) {
        return (e.altKey || (e.ctrlKey && e.altKey));
    }

    /**
     * Helper method to check if the Alt and Shift key or Alt and Ctrl and Shift keys are held down
     * @param {Event} e 
     * @returns Boolean
     */
    _modifierKeysAndShiftDown(e) {
        return ((e.altKey && e.shiftKey) || (e.ctrlKey && e.altKey && e.shiftKey));
    }

    /**
     * Compares the keys held down to the list of hotkeys, and sends off the first handler matching hotkeys we have held down to the runHandler method for execution
     * @param {Event} e 
     */
    _handleInput(e) {
        // Order of these if statements can matter if two hotkeys have similar keys held down - be careful if you're changing the order. 
        if (this._keyIsDown(67) && this._modifierKeysDown(e)) { // Alt + C / Control + Option + C
            this._runHandler(e, this.handlers.openTableOfContents);
        } else if (this._keyIsDown(66) && this._modifierKeysAndShiftDown(e)) { // Alt + Shift + B / Control + Option + Shift + B
            this._runHandler(e, this.handlers.openListOfBookmarks);
        } else if (this._keyIsDown(66) && this._modifierKeysDown(e)) { // Alt + B / Control + Option + B
            this._runHandler(e, this.handlers.addBookmark);
        } else if (this._keyIsDown(87) && this._modifierKeysDown(e)) { // Alt + W / Control + Option + W
            this._runHandler(e, this.handlers.openWhereAmI);
        } else if (this._keyIsDown(80) && this._modifierKeysDown(e)) { // Alt + P / Control + Option + P
            this._runHandler(e, this.handlers.goToPage);
        } else if (this._keyIsDown(84) && this._modifierKeysDown(e)) { // Alt + T / Control + Option + T
            this._runHandler(e, this.handlers.openSettingsPane);
        } else if (this._keyIsDown(72) && this._modifierKeysDown(e)) { // Alt + H / Control + Option + H
            this._runHandler(e, this.handlers.addHighlight);
        } else if (this._keysAreDown(82, 39) && this._modifierKeysDown(e)) { // Alt + R + Right arrow / Control + Option + R + Right arrow
            this._runHandler(e, this.handlers.skipAheadTts);
        } else if (this._keysAreDown(82, 37) && this._modifierKeysDown(e)) { // Alt + R + Left arrow / Control + Option + R + Left arrow
            this._runHandler(e, this.handlers.goBackTts);
        } else if (this._keyIsDown(82) && this._modifierKeysAndShiftDown(e)) { // Alt + Shift + R / Control + Option + Shift + R
            this._runHandler(e, this.handlers.stopTts);
        } else if (this._keyIsDown(82) && this._modifierKeysDown(e)) { // Alt + R / Control + Option + R
            this._runHandler(e, this.handlers.startTts);
        } else if (this._keyIsDown(37) && this._modifierKeysDown(e)) { // Alt + Back arrow / Control + Option + Back arrow
            this._runHandler(e, this.handlers.goBackToBookshare);
        } else if (this._keyIsDown(191) && this._modifierKeysAndShiftDown(e)) { // Alt + Shift + forward slash / Control + Option + Shift + forward slash
            this._runHandler(e, this.handlers.openAboutPanel);
        } else if (!(e.altKey || e.shiftKey || e.ctrlKey) && this._keyIsDown(37)) { // Left arrow
            this._runHandler(e, this.handlers.scrollLeft);
        } else if (!(e.altKey || e.shiftKey || e.ctrlKey) && this._keyIsDown(39)) { // Right arrow
            this._runHandler(e, this.handlers.scrollRight);
        } 
    }

    /**
     * Method that actually runs the handler once a hotkey combination is detected
     * @param {Event} e 
     * @param {Function} handler 
     */
    _runHandler(e, handler) {
        // We only want to preventDefault in cases where a hotkey is detected.  Otherwise, no keyboard input will work through the entire app.  
        e.preventDefault();
        handler();
    }
}