import {Navbar, Button, Container, Image, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {faCog} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import logo from '../bookshare_logo.png';
import './Header.css';

function Header(props) {

    const containerRef = React.createRef();
    const titleRef = React.createRef();
    const originalTitleSize = "1.25em";
    const overflowTitleSize = "2.6vw";

    useEffect(() => {
        // set the font size to the original size
        titleRef.current.style.fontSize = originalTitleSize;
        // check if the container is overflowing
        const isOverflowing = containerRef.current.clientWidth < containerRef.current.scrollWidth || containerRef.current.offsetWidth < containerRef.current.scrollWidth;
        // if it is overflowing, set the font size to the overflow size
        if (isOverflowing) {
            titleRef.current.style.fontSize = overflowTitleSize;
        }
    }, [containerRef, titleRef]);

    return (
        <Navbar bg="primary" role="banner">
            <Container ref={containerRef}>
                <Row className="banner-content">
                    <Col xs={{span: 6, order: 1}} sm={{span: 3, order: 1}} className="banner-icon">
                        <Navbar.Brand className="text-light nav-brand">
                            <Button className="text-light back-button" onClick={props.onReturnToBookshareButtonClicked} aria-label="Return to Bookshare">
                                <FontAwesomeIcon size="lg" icon={faChevronLeft} role="presentation" aria-hidden="true" color="#fff"/>
                            </Button>
                            <Image src={logo} className="logo" role="presentation" alt=""/>
                        </Navbar.Brand>
                    </Col>
                    <Col xs={{span: 12, order: 3}} sm={{span: 6, order: 2}} className="align-self-center banner-title">
                        <span className="justify-content-center text-light" ref={titleRef}>{props.title || ''}</span>
                    </Col>
                    <Col xs={{span: 6, order: 2}} sm={{span: 3, order: 3}} className="align-self-center banner-buttons">
                        <Button className="justify-content-end text-light float-right" onClick={props.onSettingsButtonClicked} aria-label="Settings">
                            <FontAwesomeIcon icon={faCog} size="lg" role="presentation"/>
                        </Button>
                    </Col>
                </Row>        
            </Container>
        </Navbar>        
    );
}

export default Header;