export default class Highlighter {

    constructor(options={}) {
        this.document = null;
        this.wordRects = [];
        this.sentenceRects = [];
        this.word = null;
        this.sentence = null;
        this.wordHighlightColor = options.wordHighlightColor || '#ffa500';
        this.sentenceHighlightColor = options.sentenceHighlightColor || '#ffff00';
        this.wordHighlightClass = options.wordHighlightClass || 'ttsWordHL';
        this.sentenceHighlightClass = options.sentenceHighlightClass || 'ttsSentenceHL';
    }

    /**
     * Removes the div elements that make up the word highlight from the parent document.
     */
    clearWordHighlight() {
        while (this.wordRects.length > 0) {
            this.document.body.removeChild(this.wordRects.pop());
        }
    }

    /**
     * Removes the div elements that make up the word highlight from the parent document.
     */
     clearSentenceHighlight() {
        while (this.sentenceRects.length > 0) {
            this.document.body.removeChild(this.sentenceRects.pop());
        }
    }

    /**
     * Draws highlight divs behind the specified DOM Range.
     * @param {Range} range 
     */
    highlightWord(range) {
        if (range) {
            this.document = range.startContainer.ownerDocument;
            this.word = range;
            const rects = range.getClientRects();
            for (let i = 0; i < rects.length; i++) {
                const div = this.document.createElement('div');
                div.className = this.wordHighlightClass;
                div.style.position = 'absolute';
                div.style.backgroundColor = this.wordHighlightColor;
                div.style.zIndex = -1;
                div.style.top = (rects[i].top) + 'px';
                div.style.left = (rects[i].left) + 'px';
                div.style.width = rects[i].width + 'px';
                div.style.height = rects[i].height + 'px';
                this.wordRects.push(div);
                this.document.body.appendChild(div);
            }
        }
    }
 
    /**
     * Draws highlight divs behind the specified DOM Range.
     * @param {Range} range 
     */
     highlightSentence(range) {
        if (range) {
            this.document = range.startContainer.ownerDocument;
            this.sentence = range;
            const rects = range.getClientRects();
            for (let i = 0; i < rects.length; i++) {
                const div = this.document.createElement('div');
                div.className = this.sentenceHighlightClass;
                div.style.position = 'absolute';
                div.style.backgroundColor = this.sentenceHighlightColor;
                div.style.zIndex = -2;
                div.style.top = (rects[i].top) + 'px';
                div.style.left = (rects[i].left) + 'px';
                div.style.width = rects[i].width + 'px';
                div.style.height = rects[i].height + 'px';
                this.sentenceRects.push(div);
                this.document.body.appendChild(div);
            }
        }
    }

    redrawWordHighlight() {
        this.clearWordHighlight();
        this.highlightWord(this.word);
    }

     redrawSentenceHighlight() {
         this.clearSentenceHighlight();
         this.highlightSentence(this.sentence);
    }
}