import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import RadioSet from "./RadioSet";
import { FLOWS, PAGE_MARGINS, LINE_HEIGHTS } from "../utils/enums";

class PageTab extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const displayRadioLabel = "displayFormatRadioLabel";
    const marginsRadioLabel = "marginsRadioLabel";
    const lineSpacingRadioLabel = "lineSpacingRadioLabel";

    return (
      <>
        <Row>
          <Col xs={12} sm={3}>
            <div className="setting-header">
              <strong id={displayRadioLabel}>Display Format</strong>
            </div>
          </Col>
          <Col xs={12} sm={9}>
            <RadioSet
              name="flow"
              options={FLOWS}
              value={this.props.viewerSettings.flow}
              radioGroupLabel={displayRadioLabel}
              onChange={this.props.onHandleFlowChange}
              disabled={this.props.bookHasMath}
              disabledMessage={
                "Books containing MathML can only be viewed using the Scrolled format."
              }
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3}>
            <div className="setting-header">
              <strong id={marginsRadioLabel}>Margins</strong>
            </div>
          </Col>
          <Col xs={12} sm={9}>
            <RadioSet
              name="margin"
              options={PAGE_MARGINS}
              value={this.props.viewerSettings.margin}
              radioGroupLabel={marginsRadioLabel}
              onChange={this.props.onHandleMarginChange}
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3}>
            <div className="setting-header">
              <strong id={lineSpacingRadioLabel}>Line Spacing</strong>
            </div>
          </Col>
          <Col xs={12} sm={9}>
            <RadioSet
              name="lineHeight"
              options={LINE_HEIGHTS}
              value={this.props.viewerSettings.lineHeight}
              radioGroupLabel={lineSpacingRadioLabel}
              onChange={this.props.onHandleLineHeightChange}
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3}>
            <div className="setting-header">
              <strong>Page Numbers</strong>
            </div>
          </Col>
          <Col xs={12} sm={9}>
            <Form.Check
              type="checkbox"
              checked={this.props.viewerSettings.displayPageNumbers}
              label="Display Page Numbers"
              onChange={this.props.onHandleDisplayPageNumber}
              id="display_page_numbers_checkbox"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default PageTab;
