import React from 'react';
import {Modal, Nav, Tab, Tabs} from 'react-bootstrap';
import BookmarksTab from './BookmarksTab';
import NotesTab from './NotesTab';
import './NavigationPanel.css';
import ComboBox from './ComboBox';

class NavigationPanel extends React.Component {

    constructor(props) {
        super(props);
        this.handleNavLinkClicked = this.handleNavLinkClicked.bind(this);
        this.handleTocLinkClicked = this.handleTocLinkClicked.bind(this);
        this.handleBookmarkLinkClicked = this.handleBookmarkLinkClicked.bind(this);
        this.triggeringElement = null;

        this.onShow = this.onShow.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onShow() {
        this.triggeringElement = document.activeElement;
        const currentTocElement = document.querySelector("ol.tocNavList a.nav-link.active");
        if (currentTocElement) {
            currentTocElement.scrollIntoView();
        }
    }

    onExited() {
        if (this.triggeringElement) {
            this.triggeringElement.focus();
        }
    }

    handleNavLinkClicked = (event, attribute) => {
        event.preventDefault();
        this.triggeringElement = null;
        this.props.onNavLinkClicked && this.props.onNavLinkClicked(event.target.attributes.getNamedItem(attribute).value);
    };

    handlePageSelected = (page) => {
        const href = this.props.pageList.pageList.find(x => x.page === page.value).href;
        this.triggeringElement = null;
        this.props.onNavLinkClicked && this.props.onNavLinkClicked(this.props.baseUrl + href);
    }

    handleTocLinkClicked = (event) => {
        this.handleNavLinkClicked(event, 'href');
    };

    handleBookmarkLinkClicked = (event) => {
        this.handleNavLinkClicked(event, 'data-cfi');
    };

    renderNavSublist(tocItems, level) {
        const _level = level || 1;

        const navItems = [];

        for (let tocItem of tocItems) {
            const subItems = (tocItem.subitems.length > 0) ? this.renderNavSublist(tocItem.subitems, _level + 1) : null;
            const isCurrentTocItem = (tocItem === this.props.currentTocItem);

            const cssClasses =[];
            if (isCurrentTocItem) {
                cssClasses.push('text-light');
                cssClasses.push('bg-primary');
            }

            if (_level === 1 && subItems) {
                cssClasses.push('subhead');
            }

            navItems.push(
                <Nav.Item key={tocItem.id} as="li">
                    <div role="heading" aria-level={_level}>
                        <Nav.Link
                            onClick={this.handleTocLinkClicked}
                            href={this.props.baseUrl + tocItem.href}
                            className={cssClasses}
                            active={isCurrentTocItem}
                            aria-current={isCurrentTocItem ? 'location' : null}
                            >
                            {tocItem.label}
                        </Nav.Link>
                    </div>
                    {subItems}
                </Nav.Item>);
        }        
        return (
            <Nav as="ol" className="flex-column tocNavList">
                {navItems}
            </Nav>
        );
    }

    preparePageData(pages) {
        // Prepare list of pages to be passed into the ComboBox component
        // Some books have no page number text, so they need to be filtered out with a truthiness check.
        // See title instance 1190835 (Velveteen Rabbit) for an example.
        // ComboBox expects an array of objects with a value property.
        const pageNumbers = pages.filter(x => x).map(x => ({value: x}));

        // Prepare the placeholder text for the type-ahead input box
        const romanNumeralPages = pages.filter(x => /^[MDCLXVImdclxvi]+$/.test(x));
        const numericPages = pages.filter(x => /^[0-9]+$/.test(x));

        let placeholder = romanNumeralPages.length 
            > 0 ? `Pages ${romanNumeralPages[0]} to ${romanNumeralPages[romanNumeralPages.length - 1]}, ${numericPages[0]} to ${numericPages[numericPages.length - 1]}`
            : `Pages ${numericPages[0]} to ${numericPages[numericPages.length - 1]}`

        return [pageNumbers, placeholder];
    }

    render() {
        const nav = this.renderNavSublist(this.props.navigation ? this.props.navigation.toc : []);

        const [pages, placeholder] = this.preparePageData(this.props.pageList ? this.props.pageList.pages : []);

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onNavHide}
                onShow={this.onShow}
                onExited={this.onExited}
                scrollable={true}
                restoreFocus={false}
                aria-labelledby="navigationModalLabel"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="navigationModalLabel">Book Navigation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey={this.props.defaultTab || "toc"} className="nav nav-pills flex-column flex-md-row">
                        <Tab eventKey="toc" title="Table of Contents" tabClassName="reader-tab">
                            {pages.length > 0 ?
                                <ComboBox 
                                    isFocused={this.props.focusPage}
                                    items={pages}
                                    label="Go to Page: " 
                                    placeholder={placeholder}
                                    onValueSelected={this.handlePageSelected}
                                ></ComboBox>
                            : null }
                            {nav}
                        </Tab>
                        <Tab eventKey="bookmarks" title="Bookmarks" tabClassName="reader-tab">
                            <BookmarksTab
                                bookmarks={this.props.bookmarks}
                                onBookmarkLinkClicked={this.handleBookmarkLinkClicked}
                                onDeleteBookmarkButtonClicked={this.props.onDeleteBookmarkButtonClicked}
                            />
                        </Tab>
                        {process.env.REACT_APP_READER_FEATURE_HIGHLIGHTS === 'enabled' ?
                        <Tab eventKey="notes" title="Notes" tabClassName="reader-tab">
                            <NotesTab
                                highlights={this.props.highlights}
                                onHighlightLinkClicked={this.handleBookmarkLinkClicked}
                                onDeleteHighlightButtonClicked={this.props.onDeleteHighlightButtonClicked}
                            />
                        </Tab>
                        : null}
                    </Tabs>
                </Modal.Body>
            </Modal>
        );
    }

}

export default NavigationPanel;