import React from 'react';
import RadioSet from './RadioSet';
import CustomSlider from './CustomSlider';
import {Col, Row} from 'react-bootstrap';
import {QUICK_FONT_SIZES, QUICK_FONTS_FAMILIES, QUICK_PAGE_FORMATS, QUICK_COLOR_THEME_NAMES} from '../utils/enums';
import './ColorPreviewContainer.css';
import ColorPresetOption from './ColorPresetOption';

class QuicksetTab extends React.Component {

    constructor(props) {
        super(props);
        this.handleQuickFontSizeChange = this.handleQuickFontSizeChange.bind(this);
        this.handleQuickPageFormat = this.handleQuickPageFormat.bind(this);
    }

    handleQuickFontSizeChange(quickFontSizeId) {
        // get associated values
        const values = QUICK_FONT_SIZES.find(f => f.id === quickFontSizeId).value;
        // update parent state
        this.props.onFontSizeChange(values.fontSize);
        this.props.onLetterSpacingChange(values.letterSpacing);
    }

    handleQuickPageFormat(quickPageFormatId) {
        // get associated values
        const values = QUICK_PAGE_FORMATS.find(f => f.id === quickPageFormatId).value;
        // set parent component state
        this.props.onFlowChange(values.flow);
        this.props.onMarginChange(values.margin);
        this.props.onLineHeightChange(values.lineHeight);
    }

    render() {
        const colorThemes = QUICK_COLOR_THEME_NAMES.map(f => {
            const row = <Col key={f.value}>
                <ColorPresetOption
                    colorPreset={f}
                    selectedColorPreset={this.props.colorPreset}
                    onClick={this.props.onColorThemeChange}
                />
            </Col>
        
            return(row);
        });
        const colorThemesRow = <Row>{colorThemes}</Row>

        const textSizeRadioLabel = "textSizeRadioLabel";
        const fontRadioLabel = "fontRadioLabel";
        const pageFormatRadioLabel = "pageFormatRadioLabel";

        return (
            <>
                <CustomSlider
                    name="qsAudioSpeed"
                    label="Audio Speed"
                    value={this.props.audioSpeed}
                    options={this.props.audioSpeedOptions}
                    onChange={this.props.onAudioSpeedChange}
                    incrementButtonLabel={"Increase Audio Speed"}
                    decrementButtonLabel={"Decrease Audio Speed"}
                    defaultButtonLabel={"Set to Normal Speed"}
                />

                <Row>
                    <Col xs={12} sm={3}>
                        <div className="setting-header">
                            <strong id={textSizeRadioLabel}>Text Size</strong>
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        <RadioSet
                        name="quickFontSize"
                        options={QUICK_FONT_SIZES}
                        value={this.props.viewerSettings}
                        radioGroupLabel={textSizeRadioLabel}
                        onChange={this.handleQuickFontSizeChange}
                        />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12} sm={3}>
                        <div className="setting-header">
                            <strong id={fontRadioLabel}>Font</strong>
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        <RadioSet
                        name="quickFontFamily"
                        options={QUICK_FONTS_FAMILIES}
                        value={this.props.viewerSettings.fontFamily}
                        radioGroupLabel={fontRadioLabel}
                        onChange={this.props.onFontFamilyChange}
                        />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12} sm={3}>
                        <div className="setting-header">
                            <strong id={pageFormatRadioLabel}>Page Format</strong>
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        <RadioSet
                        name="quickPageFormat"
                        options={QUICK_PAGE_FORMATS}
                        value={this.props.viewerSettings}
                        radioGroupLabel={pageFormatRadioLabel}
                        onChange={this.handleQuickPageFormat}
                        />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12} sm={3}>
                        <div className="setting-header">
                            <strong>Color Themes</strong>
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        {colorThemesRow}
                    </Col>
                </Row>
            </>
        );
    }
}

export default QuicksetTab;