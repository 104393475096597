import {Button, Col, Container, Navbar, Overlay, Popover, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListUl, faMapMarkedAlt, faPause, faPlay, faRedo, faUndo, faBookmark} from '@fortawesome/free-solid-svg-icons';
import {useRef} from 'react';
import './Footer.css';

function renderTocLabel(props) {
    if (props.currentTocItem) {
        let label = props.currentTocItem.label.trim();
        if (label.length > 20) {
            label = label.substring(0, 18) + "\u2026";
        }
        return <><span><strong>{label}</strong></span><br/></>;
    } else {
        return <></>;
    }
}

function renderPageLabel(props) {
    if (props.currentPrintPage) {
        return <><span>Page {props.currentPrintPage} of {props.pageList.pages.length}</span><br/></>;
    } else {
        return <></>;
    }
}

function Footer(props) {

    let whereAmIRef = useRef(null);

    const whereAmIPopover = (
        <Popover id="where-am-i-popover">
            <Popover.Title><strong>Current Location</strong></Popover.Title>
            <Popover.Content>
                <div className="whereAmI-location">
                    {renderPageLabel(props)}
                    <span>Section {props?.currentLocation?.start?.index + 1} of {props?.spine?.length}</span><br/>
                    <span>{ Math.ceil(100 * props?.currentLocation?.start?.displayed?.page / props?.currentLocation?.start?.displayed?.total) }% of section</span>
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <Navbar bg="primary" className="order-2">
            <Container>
                <Row className="flex-grow-1">
                    <Col xs={3} className="d-flex justify-content-start">
                        <Button 
                            className="text-light" 
                            onClick={props.onNavButtonClicked}
                            aria-label="Book Navigation">
                            <FontAwesomeIcon icon={faListUl} size="lg" role="presentation"/>
                        </Button>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-center">
                        <Button
                            className="text-light"
                            onClick={props.onSkipBackButtonClicked}
                            disabled={!props.ttsPlaying}
                            aria-label="Audio skip back"
                            >
                            <FontAwesomeIcon icon={faUndo} size="lg" role="presentation"/>
                        </Button>
                        <Button 
                            className="text-light" 
                            onClick={props.onPlayButtonClicked}
                            aria-label={props.ttsPlaying ? 'Pause' : 'Play'}
                            >
                            <FontAwesomeIcon
                                icon={props.ttsPlaying ? faPause : faPlay}
                                size="lg"
                                role="presentation"
                                />
                        </Button>
                        <Button
                            className="text-light"
                            onClick={props.onSkipAheadButtonClicked}
                            disabled={!props.ttsPlaying}
                            aria-label="Audio skip ahead"
                            >
                            <FontAwesomeIcon icon={faRedo} size="lg" role="presentation"/>
                        </Button>   
                    </Col>
                    <Col xs={3} className="d-flex justify-content-end">
                        <Button 
                            ref={whereAmIRef}
                            className="text-light"
                            aria-label="Where am I?"
                            onClick={props.onWhereAmIClicked}>
                            <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" role="presentation"/>
                        </Button>
                        <Overlay show={props.showWhereAmI} target={whereAmIRef} placement="top">
                            {whereAmIPopover}
                        </Overlay>
                        <Button
                            className="text-light"
                            onClick={props.onBookmarkButtonClicked}
                            aria-label="Bookmark current location"
                            >
                            <FontAwesomeIcon icon={faBookmark} size="lg" role="presentation"/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
}

export default Footer;