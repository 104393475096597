import { EpubCFI } from "epubjs";
import AnnotationApiClient from "./AnnotationApiClient";
import Bookmark from "./Bookmark";
import Highlight from "./Highlight";
import AutoSortingArray from "../AutoSortingArray";

export default class Annotator {

    /**
     * Creates a new Annotator object.
     * @param {number} bookId Bookshare title instance ID
     * @param {Storage} [storageProvider] Storage-shaped object
     * @param {AnnotationApiClient} [apiClient] API client that provides bookmark/highlight access
     */
    constructor(bookId, storageProvider, apiClient) {

        /** @type {number} */
        this.bookId = bookId;

        /** @type {AutoSortingArray} */
        this.bookmarks = new AutoSortingArray((a, b) => this.cfiComparator.compare(a.location, b.location));

        /** @type {AutoSortingArray} */
        this.highlights = new AutoSortingArray(
            (a, b) => {
                // Check the start points of both CFIs first.
                const startComparison = this.cfiComparator.compare(a.location, b.location);
                if (startComparison !== 0) {
                    return startComparison;
                } else {
                    // If both CFIs have the same start point, compare their end points.
                    const cfiA = new EpubCFI(a.location);
                    const cfiB = new EpubCFI(b.location);
                    cfiA.collapse();
                    cfiB.collapse();
                    return this.cfiComparator.compare(cfiA, cfiB);
                }
            }
        );

        /** @type {EpubCFI} */
        this.cfiComparator = new EpubCFI();

        this.storageProvider = storageProvider || null;

        this.apiClient = apiClient || null;
    }

    /** BOOKMARKS */

    /** Internal functions for Bookmark saves */
    _bookmarkSave(b) {
      return this.apiClient?.saveBookmark(b)
        .then(() => {
          b.synced = true;
          this.storageProvider?.storeBookmarks(this.bookId, this.listAllBookmarks());
        })
        .catch((e) => {console.log('Save failed.'); console.log(e);});
    }

    _bookmarkUpdate = (b) => {
      // No update API so we're doing this via chained delete/save
      return this.apiClient?.deleteBookmark(b)
        .then(() => this.apiClient?.saveBookmark(b))
        .then(() => {
          b.synced = true;
          this.storageProvider?.storeBookmarks(this.bookId, this.listAllBookmarks());
        })
        .catch((e) => {console.log('Update failed.'); console.log(e);});
    }

    _bookmarkDelete(b) {
      return this.apiClient?.deleteBookmark(b)
        .then(() => {
          this.bookmarks.remove(x => x.location === b.location);
          this.storageProvider?.storeBookmarks(this.bookId, this.listAllBookmarks());
        })
        .catch((e) => {console.log('Delete failed.'); console.log(e);});
    }

    /**
     * Create a new bookmark with the given location and text. If
     * a bookmark already exists with the given location, replace it
     * with the new one.
     * @param {string} location Location as a CFI string
     * @param {string} text Text snippet from the bookmarked location
     * @param {object} locator Optional Readium Locator object
     * @returns {Bookmark} the created Bookmark
     */
    addBookmark(location, text, locator) {
        const b = new Bookmark(this.bookId, location, text, locator);
        // Pop any existing bookmark at that location
        this.bookmarks.remove(b => b.location === location);
        // Add in the new one
        this.bookmarks.add(b);
        this.storageProvider?.storeBookmarks(this.bookId, this.listAllBookmarks());
        this._bookmarkSave(b);
        return b;
    }

    /**
     * Removes the bookmark at the given location.
     * @param {string} location CFI of bookmark to delete
     * @returns {Bookmark} The deleted bookmark, or null if no bookmark was found for the location.
     */
    removeBookmark(location) {
        const b = this.bookmarks.items.find(b => b.location === location);
        if (b) {

            // No actual delete just yet, mark as deleted and notify the server.
            b.shouldDelete = true;
            this.storageProvider?.storeBookmarks(this.bookId, this.listAllBookmarks());
            this._bookmarkDelete(b);

            // This returns immediately so the caller knows that the delete was
            // processed. listActiveBookmarks() works off this object's internal
            // state so it already reflects this bookmark's removal.
            return b;
        } else {
            return null;
        }
    }

    /**
     * Returns Bookmarks that are not marked for deletion.
     * @returns {Bookmark[]} Array of bookmarks
     */
    listActiveBookmarks() {
        return this.bookmarks.items.filter(b => !b.shouldDelete);
    }

    /**
     * Returns all Bookmarks, even those marked for deletion
     * @returns {Bookmark[]} Array of bookmarks
     */
     listAllBookmarks() {
        return this.bookmarks.items;
    }

    /**
     * @returns {Promise<Bookmark[]>}
     */
    initializeBookmarks() {
        // clear any existing bookmarks
        this.bookmarks.clear();

        // Load local bookmarks
        const localBookmarks = this.loadBookmarksFromStorage();
 
        // Load from server
        if (this.apiClient) {
            return this.apiClient?.getBookmarks(this.bookId)
                .then((remoteBookmarks) => {
                    this.reconcileBookmarks(localBookmarks, remoteBookmarks);
                    return this.listActiveBookmarks();
                })
                .catch((e) => {
                    console.log('Bookmark retrieval failed, falling back to stored bookmarks.');
                    console.log(e);
                    this.bookmarks.add(...localBookmarks);
                    return this.listActiveBookmarks();
                });
        } else {
            this.bookmarks.add(...localBookmarks);
            return Promise.resolve(this.listActiveBookmarks());
        }
    }

    /**
     * @returns {Bookmark[]}
     */
    loadBookmarksFromStorage() {
        if (this.storageProvider) {
            return this.storageProvider.loadBookmarks(this.bookId);
        } else {
            return [];
        }
    }

    /**
     * Reconciles local and server bookmarks and updates the internal bookmark property.
     * Side-effects galore here.
     * @param {Bookmark[]} local
     * @param {Bookmark[]} remote
     */
    reconcileBookmarks(local, remote) {

        const result = reconcileAnnotations(local, remote,
            b => b.dateCreated,
            b => this._bookmarkSave(b),
            b => this._bookmarkUpdate(b),
            b => this._bookmarkDelete(b));

        this.bookmarks.clear();
        this.bookmarks.add(...result.values());
        this.storageProvider?.storeBookmarks(this.bookId, this.listAllBookmarks());
    }

    /** HIGHLIGHTS */

    /** Internal functions for Highlight saves */
    _highlightSave(h) {
      return this.apiClient?.saveHighlight(h)
          .then(() => {
          h.synced = true;
          this.storageProvider?.storeHighlights(this.bookId, this.listAllHighlights());
          })
          .catch((e) => {console.log('Save failed.'); console.log(e);});
    }
  
    // TODO: Complete the wiring for the update path, we have an update method
    _highlightUpdate = (h) => {
      return this.apiClient?.deleteHighlight(h)
        .then(() => this.apiClient?.saveHighlight(h))
        .then(() => {
          h.synced = true;
          this.storageProvider?.storeHighlights(this.bookId, this.listAllHighlights());
        })
        .catch((e) => {console.log('Update failed.'); console.log(e);});
    }
  
    _highlightDelete(h) {
      return this.apiClient?.deleteHighlight(h)
        .then(() => {
          this.highlights.remove(x => x.location === h.location);
          this.storageProvider?.storeHighlights(this.bookId, this.listAllHighlights());
        })
        .catch((e) => {console.log('Delete failed.'); console.log(e);});
    }

    /**
     * Create a new highlight with the given attributes. If a highlight already exists with
     * the given CFI location points, the new one replaces it.
     * @param {string} location Highlight location expressed as a CFI range string
     * @param {string} text     Complete text (not an excerpt) of the highlighted range, with
     *                          whitespace sequences collapsed.
     * @param {object} color    Color of highlight, represented as RGB hex code (e.g. '#CC3399')
     * @param {object} note     Optional user-entered text notes
     * @param {object} locator  Optional Readium Locator object
     * @returns {Highlight} The created highlight.
     */
    addHighlight(location, text, color, note, locator) {
        const h = new Highlight(this.bookId, location, text, color, note, locator);
        // Pop any existing highlight at that location
        this.highlights.remove(h => h.location === location);
        this.highlights.add(h);
        this.storageProvider?.storeHighlights(this.bookId, this.listAllHighlights());
        this._highlightSave(h);
        return h;
    }

    /**
     * Removes the highlight at the given CFI range
     * @param {string} location CFI of highlight to delete
     * @returns {Highlight} The deleted highlight, or null if no highlight was found for the CFI range.
     */
    removeHighlight(location) {
        const h = this.highlights.items.find(h => h.location === location);
        if (h) {
            h.shouldDelete = true;
            this.storageProvider?.storeHighlights(this.bookId, this.listAllHighlights());
            this._highlightDelete(h);
            return h;
        } else {
            return null;
        }
    }

    /**
     * Returns Highlights that are not marked for deletion.
     * @returns {Highlight[]} Array of highlights
     */
    listActiveHighlights() {
        return this.highlights.items.filter(h => !h.shouldDelete);
    }

    /**
     * Returns all Highlights, even those marked for deletion
     * @returns {Highlight[]} Array of highlights
     */
     listAllHighlights() {
        return this.highlights.items;
    }

    /**
     * @returns {Promise<Highlight[]>}
     */
    initializeHighlights() {
        // Clear any existing highlights
        this.highlights.clear();

        // Load local highlights
        const localHighlights = this.loadHighlightsFromStorage();

        // Load from server
        if (this.apiClient) {
            return this.apiClient?.getHighlights(this.bookId)
                .then((remoteHighlights) => {
                    this.reconcileHighlights(localHighlights, remoteHighlights);
                    return this.listActiveHighlights();
                })
                .catch((e) => {
                    console.log('Highlights retrieval failed, falling back to stored highlights.');
                    console.log(e);
                    this.highlights.add(...localHighlights);
                    return this.listActiveHighlights();
                });
        } else {
            this.highlights.add(...localHighlights);
            return Promise.resolve(this.listActiveHighlights());
        }

    }

    /**
     * @returns {Highlight[]}
     */
    loadHighlightsFromStorage() {
        if (this.storageProvider) {
            return this.storageProvider.loadHighlights(this.bookId);
        } else {
            return [];
        }
    }

    /**
     * Reconciles local and server highlights and updates the internal highlights property.
     * Side-effects galore here.
     * @param {Highlight[]} local
     * @param {Highlight[]} remote
     */
    reconcileHighlights(local, remote) {

        const result = reconcileAnnotations(local, remote,
            h => h.dateUpdated,
            h => this._highlightSave(h),
            h => this._highlightUpdate(h),
            h => this._highlighDelete(h));

        this.highlights.clear();
        this.highlights.add(...result.values());
        this.storageProvider?.storeHighlights(this.bookId, this.listAllHighlights());
    }

}

/**
 * Reconciles local and server annotations and updates the corresponding internal property.
 * @param {Number} bookId Book ID.
 * @param {Bookmark[] | Highlight[]} local  Annotations received from device storage
 * @param {Bookmark[] | Highlight[]} remote Annotations recevied from server
 * @param {Function} dateFn function for retrieving date used for comparisons
 * @param {Function} saveFn function to save the annotation via the API
 * @param {Function} updateFn function to update the annotation via the API
 * @param {Function} deleteFn function to delete an annotation via the API
 * @returns {Map<string, Bookmark | Highlight>} Returns a Map of annotations keyed by location
 */
export function reconcileAnnotations(local, remote, dateFn, saveFn, updateFn, deleteFn) {

    // Initialize a working set composed of the remote annotations.
    const workingSet = new Map(remote.map(a => [a.location, a]));

    local.forEach((a) => {
        if (!a.synced && !a.shouldDelete && !workingSet.has(a.location)) {
            // Local annotation that has not been saved to the server.
            // Add it to the working set and queue up an API save.
            workingSet.set(a.location, a);
            saveFn(a);
        } else if (workingSet.has(a.location) && dateFn(workingSet.get(a.location)) < dateFn(a)) {
            // Local annotation appears to be newer than remote annotation for the same location.
            // Use the local annotation in the working set and issue an update for the remote.
            // Note that it doesn't matter if the local annotation is synced -- it's still for the
            // same location and is newer, so it "wins," and it's always an update because the
            // location is occupied in the remote set.
            workingSet.set(a.location, a);
            updateFn(a);
        } else if (a.synced && a.shouldDelete && dateFn(a) >= dateFn(workingSet.get(a.location))) {
            // Server-synced annotation has been marked for deletion locally.
            // Keep it in memory but queue up a delete.
            // (If it was never synced, there's no remote item to delete. If its creation date
            // precedes the remote version's date, then the remote version is newer and "wins.")
            workingSet.set(a.location, a);
            deleteFn(a);
        }
    });

    // Return the working set
    return workingSet;
}