import { Dropdown, Row, Col } from 'react-bootstrap';
import './ColorDropdown.css';

function ColorDropdown(props) {

    // Create a dropdown menu item that shows the color name and a preview of the color
    const colorOptions = props.options.map(f => {
        return (
            <Dropdown.Item key={f.value} eventKey={f.value}>
                <Row>
                    <Col xs={8}>
                        {f.label}
                    </Col>
                    <Col xs={4}>
                        <div className="color-dropdown-item-color"
                            style={{backgroundColor: f.value}}>
                        </div>
                    </Col>
                </Row>
            </Dropdown.Item>
        );
    });

    return (
        <Dropdown onSelect={props.onSelect}>
            <Dropdown.Toggle id={props.id} className="color-dropdown" aria-labelledby={props.dropDownLabel}>
                <div className="color-dropdown-preview"
                    style={{backgroundColor: props.stateValue}}>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="color-dropdown-menu">
                {colorOptions}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default ColorDropdown;