export const SearchDirection = {
    FORWARD: 0,
    BACKWARD: 1
};

/**
 * @param {Node} node 
 * @returns {boolean} True if node is non-null and a text or CDATA node.
 */
export function isTextNode(node) {
    return node && (node.nodeType === Node.TEXT_NODE || node.nodeType === Node.CDATA_SECTION_NODE);
}

/**
 * @param {Node} node 
 * @returns {boolean} True if node is non-null and an Element node.
 */
export function isElementNode(node) {
    return node && node.nodeType === Node.ELEMENT_NODE;
}

/**
 * @param {Node} node 
 * @returns {boolean} True if node is non-null, and an Element node with no children.
 */
export function isEmptyElement(node) {
    return node && isElementNode(node) && node.childNodes.length === 0;
}

/**
 * Generates a text excerpt from the given range, including image alt text and
 * MathML voicing. Collapses long runs of whitespace into single spaces.
 * @param {Range} range Range for which we are generating the text excerpt.
 */
export function getTextExcerpt(range) {

    const doc = range.startContainer.ownerDocument;

    // Create a document fragment out of the range
    const f = range.cloneContents();

    // Find all images and replace with a <span> containing their alt text or filename.
    f.querySelectorAll('img').forEach((img) => {
        const span = doc.createElement('span');
        span.textContent = ` ${img.alt || img.src.substring(img.src.lastIndexOf('/') + 1)} `;
        img.replaceWith(span);
    });

    // Find all MJX containers and replace with a <span> the aria label
    f.querySelectorAll('mjx-container').forEach((mjx) => {
        const span = doc.createElement('span');
        span.textContent = ` ${mjx.getAttribute('aria-label') || 'Math expression'} `;
        mjx.replaceWith(span);
    });

    return f.textContent.replace(/\s{2,}/g, ' ').trim();
}

/**
 * Walks the document tree to find the next element that satisfies the provided matcher function.
 * @param {Node} startNode Node to start the walk from.
 * @param {Function} matcher Function to test elements against.
 * @param {SearchDirection.FORWARD | SearchDirection.BACKWARD} direction Specify whether to walk forward or backward.
 * @returns {Element} Returns first matching element found, null if no matches are found.
 */
export function findClosestMatchingElement(startNode, matcher, direction) {

    const document = startNode.ownerDocument;
    const walker = document.createTreeWalker(document.body,
        NodeFilter.SHOW_ELEMENT,
        (e) => matcher(e) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP);

    walker.currentNode = startNode;

    if (direction === SearchDirection.BACKWARD) {
        return walker.previousNode();
    } else {
        return walker.nextNode();
    }
}

/**
 * Walks the provided Range to find the first element that satisfies the provided matcher function.
 * @param {Range} range Range to search
 * @param {Function} matcher Function to test elements against.
 * @returns {Element} Returns first matching element found, null if no matches are found.
 */
export function findMatchingElementInRange(range, matcher) {

    const fragment = range.cloneContents();
    const walker = fragment.ownerDocument.createTreeWalker(fragment.getRootNode(),
        NodeFilter.SHOW_ELEMENT,
        (e) => matcher(e) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP);

    return walker.nextNode();
}