import Bookmark from './Bookmark';
import Highlight from './Highlight';
import { cfiPairFromCfiRange, cfiRangeFromCfiPair } from '../CfiUtils';

const URI_PATH_BOOKMARKS = '/bookmarks';
const URI_PATH_HIGHLIGHTS = '/highlights';

export default class AnnotationApiClient {

    /**
     * Retrieves bookmarks
     * @param {number} bookId
     * @returns {Promise<Bookmark[]>} Promise that resolves to an array of Bookmarks
     */
    async getBookmarks(bookId) {
        const qsParams = new URLSearchParams([
            ['bookshareId', bookId],
        ]);
        const url = `${URI_PATH_BOOKMARKS}?${qsParams.toString()}`;

        // This one we want quickly, and we have a local fallback, so no retry.
        const response = await this.fetchWithRetry(url, { method: 'GET', retries: 0 });
        const parsedJson = await response.json();

        /** @type {Bookmark[]} */
        const result = [];
        parsedJson.forEach(j => {
            const b = Bookmark.fromJson(j);
            // Server-retrieved bookmarks are always synced
            b.synced = true;
            result.push(b);
        });
        return result;
    }

    /**
     * Saves a single bookmark
     * @param {Bookmark} bookmark
     * @returns {Promise<Response>}
     */
    saveBookmark(bookmark) {
        const formParams = new URLSearchParams([
            ['bookshareId', bookmark.bookId],
            ['location', bookmark.location],
            ['text', bookmark.text]
        ]);
        const url = URI_PATH_BOOKMARKS;
        const locatorJson = JSON.stringify(bookmark.locator, ['position', 'progression', 'totalProgression']);
        if (locatorJson !== '{}') {
            formParams.set('locator', locatorJson);
        }

        return this.fetchWithRetry(url, {method: 'POST', body: formParams.toString()});
    }

    /**
     * Deletes a single bookmark
     * @param {Bookmark} bookmark
     * @returns {Promise<Response>}
     */
    deleteBookmark(bookmark) {
        const qsParams = new URLSearchParams([
            ['bookshareId', bookmark.bookId],
            ['location', bookmark.location]
        ]);
        const url = `${URI_PATH_BOOKMARKS}?${qsParams.toString()}`;

        return this.fetchWithRetry(url, {method: 'DELETE'});
    }

    /**
     * Retrieves highlights
     * @param {number} bookId
     * @returns {Promise<Highlight[]>} Promise that resolves to an array of Highlights
     */
    async getHighlights(bookId) {
        const qsParams = new URLSearchParams([
            ['bookshareId', bookId],
        ]);
        const url = `${URI_PATH_HIGHLIGHTS}?${qsParams.toString()}`;

        // This one we want quickly, and we have a local fallback, so no retry.
        const response = await this.fetchWithRetry(url, { method: 'GET', retries: 0 });
        const parsedJson = await response.json();

        /** @type {Highlight[]} */
        const result = [];
        parsedJson.forEach(j => {
            const h = Highlight.fromJson(j);
            // Server-retrieved highlights are always synced
            h.synced = true;
            result.push(h);
        });
        return result;
    }

    /**
     * Saves a single highlight
     * @param {Highlight} highlight
     * @returns {Promise<Response>}
     */
    saveHighlight(highlight) {

        const cfiPair = cfiPairFromCfiRange(highlight.location);

        const formParams = new URLSearchParams([
            ['bookshareId', highlight.bookId],
            ['startLocation', cfiPair.start],
            ['endLocation', cfiPair.end],
            ['text', highlight.text || ''],
            ['color', highlight.color],
            ['note', highlight.note || '']
        ]);
        const url = URI_PATH_HIGHLIGHTS;
        const locatorJson = JSON.stringify(highlight.locator, ['position', 'progression', 'totalProgression']);
        if (locatorJson !== '{}') {
            formParams.set('locator', locatorJson);
        }

        return this.fetchWithRetry(url, {method: 'POST', body: formParams.toString()});
    }

    /**
     * Deletes a single highlight
     * @param {Highlight} highlight
     * @returns {Promise<Response>}
     */
    deleteHighlight(highlight) {
        const cfiPair = cfiPairFromCfiRange(highlight.location);
        const qsParams = new URLSearchParams([
            ['bookshareId', highlight.bookId],
            ['startLocation', cfiPair.start],
            ['endLocation', cfiPair.end]
        ]);
        const url = `${URI_PATH_HIGHLIGHTS}?${qsParams.toString()}`;

        return this.fetchWithRetry(url, {method: 'DELETE'});
    }

    /**
     * Works the same as fetch but adds support for retries, controlled by 3 new
     * init options:
     * - retries: max number of retries
     * - retryAttempts: number of retries already attempted
     * - delay: delay in milliseconds between retries
     * 
     * Useful even if you don't want to use the retry mechanism, because it
     * automatically rejects any non-200 response.
     * 
     * @param {RequestInfo | URL} input URL
     * @param {*} init 
     * @returns {Promise<Response>}
     */
    fetchWithRetry(input, init = {}) {
        const { retryAttempts = 0, retries = 2, delay = 3000, ...options} = init;
        const response = fetch(input, options)
            .then((response) => {
                if (response.status === 200) {
                    // Resolves with success
                    return response;
                } else {
                    // Throws an error to trigger the catch
                    throw new Error(`Response was HTTP ${response.status} ${response.statusText}: ${response.body}`);
                }
            })
            .catch(() => {
                // May be triggered by either a fetch rejection or a thrown error due to non-200 HTTP responses
                if (retryAttempts < retries) {
                    const retryOptions = { retries: retries, retryAttempts: retryAttempts + 1, delay: delay};

                    Object.assign(retryOptions, options);

                    // Use setTimeout to queue up another attempt
                    return new Promise((resolve) => {
                        setTimeout(() => resolve(this.fetchWithRetry(input, retryOptions)), delay);
                    });
                } else {
                    throw new Error(`${options.method || 'GET'} request to ${input} failed after ${retryAttempts} retries.`);
                }
            });
        return response;
    }    
}

