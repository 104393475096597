import { eq } from "../CommonUtils";

export default class Bookmark {

    /**
     * @param {number} bookId Bookshare book ID
     * @param {string} location CFI location
     * @param {string} text Text excerpt
     * @param {*} locator Readium-compatible locator
     * @param {string} dateCreated Date as an ISO string
     * @param {boolean} synced Indicates whether bookmark has been saved with a remote service.
     * @param {boolean} synced Indicates whether the bookmark has been marked for deletion.
     */
    constructor(bookId, location, text, locator = {}, dateCreated = null, synced = false, shouldDelete = false) {
        /** @type {Number} Bookshare title instance ID */
        this.bookId = bookId;

        /** @type {string} String representation of location CFI */
        this.location = location;

        /** @type {string} Textual content of Bookmark */
        this.text = text;

        /** @type {object} Additional locator data */
        this.locator = locator;

        /** @type {boolean} True if previously synced to the server */
        this.synced = synced;

        /** @type {boolean} True if bookmark is pending server deletion */
        this.shouldDelete = shouldDelete;

        /** @type {Date} Date created */
        const dateInMilliseconds = Date.parse(dateCreated);
        this.dateCreated = isNaN(dateInMilliseconds) ? new Date() : new Date(dateInMilliseconds);
    }

    equals(other) {
        return (
            this.bookId === other.bookId
                && this.location === other.location
                && this.text === other.text
                && eq(this.locator, other.locator)
                && this.synced === other.synced
                && this.shouldDelete === other.shouldDelete
                && this.dateCreated.getTime() === other.dateCreated.getTime()
        );
    }

    /**
     * Creates a new Bookmark from a JSON string.
     * @param {string} jsonString
     */
    static fromJsonString(jsonString) {
        return Bookmark.fromJson(JSON.parse(jsonString));
    }

    /**
     * Creates a new Bookmark from a JSON object.
     * @param {*} json
     */
     static fromJson(json) {
        return new Bookmark(json.bookId || json.bookshareId, json.location, json.text, json.locator,
            json.dateCreated, json.synced, json.shouldDelete);
    }

}
