
/**
 * Nested deep equality
 * @param {*} a item for comparison
 * @param {*} b item for comparison
 */
export function eq(a, b) {

    // if one argument is defined but the other is not, return false
    if ((a === undefined && b !== undefined) || (a !== undefined && b === undefined)) {
        return false;
    }

    // if one argument is null but the other is not, return false
    if ((a === null && b !== null) || (a !== null && b === null)) {
        return false;
    }

    // Strict equality should take care of strings, numbers, booleans, and references to same object.
    // Also handles both args null or both args undefined.
    if (a === b) {
        return true;
    }

    // Could be an array
    if (Array.isArray(a) && Array.isArray(b)) {
        // Proceed only if length matches
        if (a.length === b.length) {
            for (let i = 0; i < a.length; i++) {
                if (!eq(a[i], b[i])) {
                    return false;
                }
            }
            // Cycled through all indices, return true.
            return true;
        }
    }

    // Could be an object
    if (typeof(a) === 'object' && typeof(b) === 'object') {
        const aKeys = Object.keys(a);
        const bKeys = Object.keys(b);

        // Proceed only if the key arrays are same size
        if (aKeys.length === bKeys.length) {
            for (let i = 0; i < aKeys.length; i++) {
                const k = aKeys[i];
                if (!eq(a[k], b[k])) {
                    return false;
                }
            }
            // Cycled through all keys, return true.
            return true;
        }
    }

    return false;

}