import Bookmark from "./Bookmark";
import { deltaLuma } from "../CssUtils";

export default class BookmarkRenderer {

    /**
     * This very deeply intertwingled class is a big parasite that clamps on to the EpubViewer.
     * @param {*} epubViewer
     */
    constructor(epubViewer) {
        this.epubViewer = epubViewer;
        this.currentMarks = {}; // mainly used to prevent redrawing already-existing marks
        this.parser = new DOMParser();
    }

    /**
     * Draw the provided bookmarks. Computes the delta for marks to add and remove.
     * @param {Array<Bookmark>} bookmarks
     */
    drawBookmarks(bookmarks) {
        // Remove any existing bookmarks that aren't in the provided set
        const locations = bookmarks.map(bm => bm.location);

        for (let l in this.currentMarks) {
            if (locations.indexOf(l) === -1) {
                this.clearBookmark(l);
                delete this.currentMarks[l];
            }
        }

        for (let l of locations) {
            if (!this.currentMarks[l]) {
                const el = this.drawBookmark(l);
                if (el) {
                    this.currentMarks[l] = el;
                }
            }
        }
    }

    /**
     * Erase all bookmarks.
     */
    clearBookmarks() {
        for (let location in this.currentMarks) {
            this.clearBookmark(location);
            delete this.currentMarks[location]
        }
    }

    /**
     * Clear out all bookmarks and draw them from scratch.
     * @param {Array<Bookmark>} bookmarks
     */
     redrawBookmarks(bookmarks) {
        this.clearBookmarks();
        this.drawBookmarks(bookmarks);
    }


    /**
     * Draw a single bookmark.
     * @param {*} location CFI to mark
     */
    drawBookmark(location) {

        // Compute offsets for absolute positioning
        const range = this.epubViewer.rendition.getRange(location);

        // We will only have a range if the CFI resolves to the currently-displayed view.
        // If there's no range, we don't need to draw it.
        if (range) {

            // There are currently issues with Range/CFI conversions that result in collapsed
            // Ranges. The try/catch logs the error to the console rather than a hard fail.

            try {
                const style = {};
                const view = this.currentView();
                const layout = view.layout;
                const vs = this.epubViewer.props.viewerSettings;

                // Nudge the rect forward by one character (Helps with Firefox, as its
                // zero-length Ranges are a wee bit ahead of the actual bookmarked point.)
                if (range.endOffset < range.endContainer.length) {
                    // Simple case: move forward by one character
                    range.setEnd(range.endContainer, range.endOffset + 1);
                } else {
                    // Exceptional case: if we're at the end of a text container, set the range
                    // end to be right after the text container.
                    range.setEndAfter(range.endContainer);
                }
                const rect = range.getClientRects()[0];

                // Always use the top edge of the first content rectangle.
                style.top = rect.top;

                // Left position depends on flow mode.
                if (vs.flow === 'paginated') {
                    // Paginated mode requires finding the left edge of the page column
                    // and then adding an offset halfway into the column gap. Column gaps
                    // are half the gutter, which itself is 1/12th of the full width.
                    style.left = (Math.floor(rect.left / layout.props.pageWidth) * layout.props.pageWidth)
                        + (layout.props.width / 48);
                } else {
                    // Scrolling is simpler, put it halfway in the gutter. epub.js uses a hard-coded
                    // gutter that is 1/12th of the full width.
                    style.left = layout.props.width / 24;
                }

                // Add a slight offset to center the SVG in the gutter; the bookmark is 16 pixels wide.
                style.left -= 8;

                // Create the absolutely-positioned anchor
                const el = view.element.ownerDocument.createElement('div');
                el.style.position = 'absolute';
                el.style.top = `${style.top}px`;
                el.style.left = `${style.left}px`;

                // Figure out what color to use; we want the one with the most luminance
                // difference versus the background color.
                const bookmarkColor = (
                    Math.abs(deltaLuma(vs.backgroundColor, vs.wordHighlightColor))
                        > Math.abs(deltaLuma(vs.backgroundColor, vs.sentenceHighlightColor))
                    ) ? vs.wordHighlightColor : vs.sentenceHighlightColor;

                // Append the svg
                const svg = `<svg data-prefix="fas" data-icon="bookmark" class="svg-inline--fa fa-bookmark fa-w-12 fa-lg" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="${bookmarkColor}" d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"></path></svg>`;
                el.appendChild(this.parser.parseFromString(svg, 'image/svg+xml').documentElement);

                return view.element.appendChild(el);
            } catch(error) {
                console.log('Error while trying to draw bookmark.');
                console.log(error);
            }
        }

        return null;
    }

    /**
     * Clear a single bookmark
     * @param {*} location CFI of mark to clear
     */
    clearBookmark(location) {
        const el = this.currentMarks[location];
        el.parentElement.removeChild(el);
        delete this.currentMarks[location];
    }

    /**
     * Convenience method for getting the first view in the views collection. Very brittle,
     * will not work for fixed-layout books, which present multiple views in two-page spreads.
     * Continuous view manager may also break.
     */
    currentView() {
        return this.epubViewer.rendition.views()._views[0];
    }
}
