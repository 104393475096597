import React from "react";
import { Form, Modal, Tabs, Tab } from "react-bootstrap";
import {
  COLOR_PRESET_MAPPINGS,
  AUDIO_SPEEDS,
  GOOGLE_AUDIO_SPEEDS,
  AUDIO_SPEED_MAPPINGS,
} from "../utils/enums";

import AudioTab from "./AudioTab";
import TextTab from "./TextTab";
import PageTab from "./PageTab";
import ColorTab from "./ColorTab";
import ViewerSettings from "../utils/ViewerSettings.js";
import QuicksetTab from "./QuicksetTab";
import ResetButton from "./ResetButton";
import "./SettingsPanel.css";

class SettingsPanel extends React.Component {
  constructor(props) {
    super(props);
    // Assign viewSettings to state
    this.state = Object.assign({}, props.viewerSettings);
    this.state.colorPreset = this.findColorPreset(this.state);

    // Handler bindings
    this.handleFontSizeChange = this.handleFontSizeChange.bind(this);
    this.handleFontFamilySelect = this.handleFontFamilySelect.bind(this);
    this.handleLetterSpacingChange = this.handleLetterSpacingChange.bind(this);
    this.handleFlowChange = this.handleFlowChange.bind(this);
    this.handleMarginChange = this.handleMarginChange.bind(this);
    this.handleLineHeightChange = this.handleLineHeightChange.bind(this);
    this.handleDisplayPageNumberCheck =
      this.handleDisplayPageNumberCheck.bind(this);
    this.handleCustomColorSelect = this.handleCustomColorSelect.bind(this);
    this.handlePresetColorChange = this.handlePresetColorChange.bind(this);
    this.handleVoiceSelect = this.handleVoiceSelect.bind(this);
    this.handleAudioSpeedChange = this.handleAudioSpeedChange.bind(this);
    this.handleVoicePageNumberCheck =
      this.handleVoicePageNumberCheck.bind(this);
    this.handleVoiceImageDesCheck = this.handleVoiceImageDesCheck.bind(this);
    this.handleResetSettingsConfirmed =
      this.handleResetSettingsConfirmed.bind(this);

    const isGoogleVoice = this.state.voice.startsWith("Google");
    this.audioSpeedEnum = isGoogleVoice ? GOOGLE_AUDIO_SPEEDS : AUDIO_SPEEDS;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.onSettingsChanged(this.exportSettings());
    }
  }

  /**
   * Resets current form state to match those of the ViewerSettings props passed in from parent.
   */
  resetSettings() {
    this.setState(Object.assign({}, this.props.viewerSettings));
  }

  /**
   * Returns ViewSettings object constructed from relevant state attributes.
   * @returns ViewSettings object
   */
  exportSettings() {
    const newSettings = new ViewerSettings();
    Object.assign(newSettings, {
      fontFamily: this.state.fontFamily,
      fontSize: this.state.fontSize,
      letterSpacing: this.state.letterSpacing,
      flow: this.state.flow,
      margin: this.state.margin,
      lineHeight: this.state.lineHeight,
      displayPageNumbers: this.state.displayPageNumbers,
      backgroundColor: this.state.backgroundColor,
      textColor: this.state.textColor,
      sentenceHighlightColor: this.state.sentenceHighlightColor,
      wordHighlightColor: this.state.wordHighlightColor,
      voice: this.state.voice,
      audioSpeed: this.state.audioSpeed,
      voicePageNumbers: this.state.voicePageNumbers,
      voiceImageDescriptions: this.state.voiceImageDescriptions,
      quickFontFamily: this.state.quickFontFamily,
      quickFontSize: this.state.quickFontSize,
      quickPageFormat: this.state.quickPageFormat,
    });
    return newSettings;
  }

  handleFontSizeChange(newFontSize) {
    this.setState({ fontSize: newFontSize });
  }

  handleFontFamilySelect(value) {
    this.setState({ fontFamily: value });
  }

  handleLetterSpacingChange(newLetterSpacing) {
    this.setState({ letterSpacing: newLetterSpacing });
  }

  handleFlowChange(newFlow) {
    this.setState({ flow: newFlow });
  }

  handleMarginChange(newMargin) {
    this.setState({ margin: newMargin });
  }

  handleLineHeightChange(newLineHeight) {
    this.setState({ lineHeight: newLineHeight });
  }

  handleDisplayPageNumberCheck(event) {
    this.setState({ displayPageNumbers: event.target.checked });
  }

  handleCustomColorSelect(key, value) {
    const colorState = this.extractColors(this.state);
    colorState[key] = value;
    this.setColorState(colorState);
  }

  handlePresetColorChange(evt) {
    // get the ID of the event's currentTarget to determine
    // which preset to use for color states
    const eventId = evt.currentTarget.id;

    // Using extractColors gives us a _copy_ of the presets, and insulates us from any
    // unexpected changes in the enum that we don't want to send into component state.
    const colorState = this.extractColors(
      COLOR_PRESET_MAPPINGS.find((f) => f.key === eventId).value
    );
    this.setColorState(colorState);
  }

  handleVoiceSelect(newVoice) {
    // check if there is a switch between google and non-google voice
    var newAudioSpeed;
    const googleToNonGoogle =
      this.state.voice.startsWith("Google") && !newVoice.startsWith("Google");
    const nonGoogleToGoogle =
      !this.state.voice.startsWith("Google") && newVoice.startsWith("Google");
    // find the corresponding speed value for the new voice
    if (googleToNonGoogle) {
      newAudioSpeed = AUDIO_SPEED_MAPPINGS.find(
        (f) => f.google === this.state.audioSpeed
      ).nonGoogle;
    } else if (nonGoogleToGoogle) {
      newAudioSpeed = AUDIO_SPEED_MAPPINGS.find(
        (f) => f.nonGoogle === this.state.audioSpeed
      ).google;
    }
    // update the audio speed enum to use the correct speeds
    this.audioSpeedEnum = newVoice.startsWith("Google")
      ? GOOGLE_AUDIO_SPEEDS
      : AUDIO_SPEEDS;
    // update the state
    this.setState({
      voice: newVoice,
      audioSpeed: newAudioSpeed ? newAudioSpeed : this.state.audioSpeed,
    });
  }

  handleAudioSpeedChange(newAudioSpeed) {
    this.setState({ audioSpeed: newAudioSpeed });
  }

  handleVoicePageNumberCheck(newChecked) {
    this.setState({ voicePageNumbers: newChecked });
  }

  handleVoiceImageDesCheck(newChecked) {
    this.setState({ voiceImageDescriptions: newChecked });
  }

  handleResetSettingsConfirmed() {
    this.setState(Object.assign({}, new ViewerSettings()));
  }

  extractColors(settings) {
    return {
      backgroundColor: settings.backgroundColor,
      textColor: settings.textColor,
      sentenceHighlightColor: settings.sentenceHighlightColor,
      wordHighlightColor: settings.wordHighlightColor,
    };
  }

  findColorPreset(colors) {
    return COLOR_PRESET_MAPPINGS.find((colorMapping) => {
      return (
        colorMapping.value.backgroundColor === colors.backgroundColor &&
        colorMapping.value.textColor === colors.textColor &&
        colorMapping.value.sentenceHighlightColor ===
          colors.sentenceHighlightColor &&
        colorMapping.value.wordHighlightColor === colors.wordHighlightColor
      );
    })?.key;
  }

  setColorState(colorState) {
    colorState.colorPreset = this.findColorPreset(colorState);
    this.setState(colorState);
  }

  render() {
    const modalLabel = "modalTitle";
    const selectedColorPreset = this.state.colorPreset;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onSettingsHide}
        dialogClassName="settings-dialog"
        contentClassName="setting-content"
        aria-labelledby={modalLabel}
      >
        <Modal.Header closeButton>
          <Modal.Title id={modalLabel}>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Tabs
              defaultActiveKey="quickSet"
              className="nav nav-pills flex-column flex-md-row"
            >
              <Tab
                eventKey="quickSet"
                title="Quick Set"
                tabClassName="reader-tab"
              >
                <QuicksetTab
                  audioSpeed={this.state.audioSpeed}
                  viewerSettings={this.props.viewerSettings}
                  colorLabelCutoff={this.props.colorLabelCutoff}
                  colorPreset={selectedColorPreset}
                  audioSpeedOptions={this.audioSpeedEnum}
                  onAudioSpeedChange={this.handleAudioSpeedChange}
                  onFontSizeChange={this.handleFontSizeChange}
                  onLetterSpacingChange={this.handleLetterSpacingChange}
                  onFontFamilyChange={this.handleFontFamilySelect}
                  onFlowChange={this.handleFlowChange}
                  onMarginChange={this.handleMarginChange}
                  onLineHeightChange={this.handleLineHeightChange}
                  onColorThemeChange={this.handlePresetColorChange}
                />
              </Tab>
              <Tab eventKey="audio" title="Audio" tabClassName="reader-tab">
                <AudioTab
                  voice={this.state.voice}
                  audioSpeed={this.state.audioSpeed}
                  voicePageNumbers={this.state.voicePageNumbers}
                  voiceImageDescriptions={this.state.voiceImageDescriptions}
                  voices={this.props.voices}
                  language={this.props.language}
                  audioSpeedOptions={this.audioSpeedEnum}
                  onVoiceSelect={this.handleVoiceSelect}
                  onAudioSpeedChange={this.handleAudioSpeedChange}
                  onVoicePageNumberChange={this.handleVoicePageNumberCheck}
                  onVoiceImageDescriptionChange={this.handleVoiceImageDesCheck}
                  onVoiceSampleRequested={this.props.onVoiceSampleRequested}
                />
              </Tab>
              <Tab eventKey="text" title="Text" tabClassName="reader-tab">
                <TextTab
                  viewerSettings={this.props.viewerSettings}
                  onFontSizeChange={this.handleFontSizeChange}
                  onFontFamilyChange={this.handleFontFamilySelect}
                  onLetterSpacingChange={this.handleLetterSpacingChange}
                />
              </Tab>
              <Tab eventKey="page" title="Page" tabClassName="reader-tab">
                <PageTab
                  viewerSettings={this.props.viewerSettings}
                  bookHasMath={this.props.bookHasMath}
                  onHandleFlowChange={this.handleFlowChange}
                  onHandleMarginChange={this.handleMarginChange}
                  onHandleLineHeightChange={this.handleLineHeightChange}
                  onHandleDisplayPageNumber={this.handleDisplayPageNumberCheck}
                />
              </Tab>
              <Tab eventKey="color" title="Color" tabClassName="reader-tab">
                <ColorTab
                  viewerSettings={this.props.viewerSettings}
                  colorLabelCutoff={this.props.colorLabelCutoff}
                  colorPreset={selectedColorPreset}
                  onPresetColorChange={this.handlePresetColorChange}
                  onCustomColorSelect={this.handleCustomColorSelect}
                />
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ResetButton
            showResetSettingsModal={this.props.showResetSettingsModal}
            onResetSettingsClicked={this.props.onResetSettingsClicked}
            onResetConfirmed={this.handleResetSettingsConfirmed}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SettingsPanel;
