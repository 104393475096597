import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import './ResetButton.css';

function ResetButton(props) {

    const handleConfirmReset = function() {
        // call the handler to reset viewer settings
        props.onResetConfirmed();
        // call the handler to hide the confirmation modal
        props.onResetSettingsClicked();
    }

    return (
        <>
            <Button 
                className="reset-button"
                onClick={props.onResetSettingsClicked}
            >
                Reset to Defaults
            </Button>

            <Modal 
                centered
                show={props.showResetSettingsModal} 
                backdrop={true}
                backdropClassName="reset-backdrop"
                onHide={props.onResetSettingsClicked}
                aria-labelledby="resetSettingsModalLabel"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="resetSettingsModalLabel">Confirm Changes</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure you want to reset your settings to default values?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={props.onResetSettingsClicked}>Cancel</Button>
                    <Button onClick={handleConfirmReset}>Reset Settings</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ResetButton;