import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";
import "./BookshareReader.css";
import { Container, Spinner } from "react-bootstrap";
import EpubViewer from "./components/EpubViewer";
import ErrorViewer from "./components/ErrorViewer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AboutPanel from "./components/AboutPanel";
import NavigationPanel from "./components/NavigationPanel";
import SettingsPanel from "./components/SettingsPanel";
import Annotator from "./utils/annotator/Annotator";
import AnnotationStorageProvider from "./utils/annotator/AnnotationStorageProvider";
import { DEFAULT_FONT_SIZE, SLIDER_FONT_SIZES } from "./utils/enums";
import ViewerSettings from "./utils/ViewerSettings.js";
import { getTextExcerpt } from "./utils/DomUtils";
import { getFilteredVoices } from "./utils/SpeechUtils";
import _throttle from "lodash.throttle";
import HotkeyManager from "./utils/HotkeyManager";
import AnnotationApiClient from "./utils/annotator/AnnotationApiClient";

class BookshareReader extends React.Component {
  constructor(props) {
    super(props);
    // get speech synth api
    const synth = window.speechSynthesis;
    this.state = {
      metadata: null,
      spine: null,
      pageList: null,
      navigation: null,
      navigationBaseUrl: null,
      navOpen: false,
      settingsOpen: false,
      aboutOpen: false,
      error: null,
      currentLocation: null,
      currentPrintPage: null,
      currentTocItem: null,
      ttsLocation: null,
      ttsPlaying: false,
      viewerSettings: this.getViewerSettings(),
      availableVoices: synth.getVoices(),
      showResetSettingsModal: false,
      showLoadingSpinner: true,
      colorLabelCutoff: 830,
      bookmarks: [],
      highlights: [],
      textSelection: null,
      navTab: null,
      focusPage: false,
      showWhereAmI: false,
      bookHasMath: false,
    };
    // create an event listener for SpeechSynthesis voices
    synth.onvoiceschanged = function () {
      this.setState({
        availableVoices: synth.getVoices(),
      });
    }.bind(this);

    // Grab reference to access imperative methods on the epubViewer's Rendition.
    this.epubViewerRef = React.createRef();

    // Flag for completion of first render
    this._firstRenderCompleted = false;

    // Annotation module
    this.annotator = new Annotator(parseInt(this.props.bookshareId));
    this.annotator.storageProvider = new AnnotationStorageProvider();
    this.annotator.apiClient = new AnnotationApiClient();
    if (process.env.NODE_ENV !== "production") {
      window.annotator = this.annotator;
    }

    // Bind handlers
    this.handleRenditionRelocated = this.handleRenditionRelocated.bind(this);
    this.handleSpeechLocationUpdated =
      this.handleSpeechLocationUpdated.bind(this);
    this.handleMetadataLoaded = this.handleMetadataLoaded.bind(this);
    this.handleSpineLoaded = this.handleSpineLoaded.bind(this);
    this.handlePageListLoaded = this.handlePageListLoaded.bind(this);
    this.handleNavigationLoaded = this.handleNavigationLoaded.bind(this);
    this.handleBookRendered = this.handleBookRendered.bind(this);
    this.handleErrorRaised = this.handleErrorRaised.bind(this);
    this.handleErrorCleared = this.handleErrorCleared.bind(this);
    this.handlePlayButtonClicked = this.handlePlayButtonClicked.bind(this);
    this.handleSettingsChanged = this.handleSettingsChanged.bind(this);
    this.handleSkipAheadButtonClicked =
      this.handleSkipAheadButtonClicked.bind(this);
    this.handleSkipBackButtonClicked =
      this.handleSkipBackButtonClicked.bind(this);
    this.handleBookmarkButtonClicked =
      this.handleBookmarkButtonClicked.bind(this);
    this.handleDeleteBookmarkButtonClicked =
      this.handleDeleteBookmarkButtonClicked.bind(this);
    this.handleNavLinkClicked = this.handleNavLinkClicked.bind(this);
    this.handleSpeechStateChange = this.handleSpeechStateChange.bind(this);
    this.toggleSettingsModal = this.toggleSettingsModal.bind(this);
    this.toggleNavModal = this.toggleNavModal.bind(this);
    this.toggleAboutModal = this.toggleAboutModal.bind(this);
    this.handleResetSettingsClicked =
      this.handleResetSettingsClicked.bind(this);
    this.cancelTts = this.cancelTts.bind(this);
    this.handleDisplaySequenceStart =
      this.handleDisplaySequenceStart.bind(this);
    this.handleDisplaySequenceEnd = this.handleDisplaySequenceEnd.bind(this);
    this.showTableOfContents = this.showTableOfContents.bind(this);
    this.showListOfBookmarks = this.showListOfBookmarks.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.showPageInput = this.showPageInput.bind(this);
    this.openWhereAmI = this.openWhereAmI.bind(this);
    this.handleScrollLeft = this.handleScrollLeft.bind(this);
    this.handleScrollRight = this.handleScrollRight.bind(this);
    this.handleTtsStarted = this.handleTtsStarted.bind(this);
    this.handleTtsStopped = this.handleTtsStopped.bind(this);
    this.handleHighlightButtonClicked =
      this.handleHighlightButtonClicked.bind(this);
    this.handleDeleteHighlightButtonClicked =
      this.handleDeleteHighlightButtonClicked.bind(this);
    this.handleTextSelectionChanged =
      this.handleTextSelectionChanged.bind(this);

    // Configure hotkey handler
    this.hotKeysHandler = () => {
      return {
        openTableOfContents: this.showTableOfContents,
        openListOfBookmarks: this.showListOfBookmarks,
        addBookmark: this.handleBookmarkButtonClicked,
        addHighlight: this.handleHighlightButtonClicked,
        goBackToBookshare: this.returnToBookshare,
        openWhereAmI: this.openWhereAmI,
        goToPage: this.showPageInput,
        openSettingsPane: this.toggleSettingsModal,
        startTts: this.handleTtsStarted,
        stopTts: this.handleTtsStopped,
        skipAheadTts: this.handleSkipAheadButtonClicked,
        goBackTts: this.handleSkipBackButtonClicked,
        scrollLeft: this.handleScrollLeft,
        scrollRight: this.handleScrollRight,
        openAboutPanel: this.toggleAboutModal,
      };
    };
    this.hotkeyManager = new HotkeyManager(this.hotKeysHandler());
    this.hotKeysHandler = this.hotKeysHandler.bind(this);
    document.addEventListener("keydown", this.hotkeyManager.handleKeyDown, {
      passive: false,
    });
    document.addEventListener("keyup", this.hotkeyManager.handleKeyUp, {
      passive: false,
    });

    // create a throttled location function
    this.throttledLocationUpdate = _throttle(this.sendLocationUpdate, 1000, {
      trailing: true,
    });

    // Expose app on non-production environments
    if (process.env.NODE_ENV !== "production") {
      window.readerApp = this;
    }

  }

  /**
   * Gets the viewer settings from local storage or returns a new viewer Settings instance
   * @returns {ViewerSettings} instance
   */
  getViewerSettings() {
    // check for local viewer settings
    const localViewerSettings = JSON.parse(
      localStorage.getItem("viewerSettings")
    );
    // if there are none, just return a new viewer settings object
    const viewerSettings = localViewerSettings
      ? this.validateViewerSettings(localViewerSettings)
      : new ViewerSettings();
    return viewerSettings;
  }

  /**
   * Function to ensure we're preserving user settings if our internal viewerSettings change
   * @param {ViewerSettings} localViewerSettings
   * @returns {ViewerSettings} instance
   */
  validateViewerSettings(localViewerSettings) {
    // function to tell if the two setings objects are the same
    const objectsHaveSameProps = (obj1, obj2) => {
      const sameLength = Object.keys(obj1).length === Object.keys(obj2).length;
      const sameProps = Object.keys(obj1).every((key) =>
        obj2.hasOwnProperty(key)
      );
      return sameLength && sameProps;
    };

    // Normalize the font size if the saved settings use old enum values
    if (
      !SLIDER_FONT_SIZES.find((s) => s.value === localViewerSettings.fontSize)
    ) {
      localViewerSettings.fontSize = DEFAULT_FONT_SIZE;
    }

    // create a new ViewerSettings object to compare keys
    const defViewerSettings = new ViewerSettings();

    // if the keys are the same between local and default, we can proceed as usual
    // if the keys are not the same, we need to copy old local settings over to a new ViewerSettings Object
    if (objectsHaveSameProps(localViewerSettings, defViewerSettings)) {
      return Object.assign(defViewerSettings, localViewerSettings);
    } else {
      Object.assign(defViewerSettings, localViewerSettings);
      // since we had to update some things, we need to change local storage
      localStorage.setItem("viewerSettings", JSON.stringify(defViewerSettings));
      return defViewerSettings;
    }
  }

  /**
   * Convenience method to determine if a modal dialog is open
   * @returns {boolean}
   */
  isModalDisplayed() {
    return (
      this.state.settingsOpen || this.state.navOpen || this.state.aboutOpen
    );
  }

  /** Begin EPUB.JS event handlers */
  handleRenditionRelocated(newLocation, tocHref, pageTitle) {
    if (process.env.NODE_ENV !== "production") {
      console.log("Location updated:");
      console.log(
        `Start index ${newLocation.start.index}, href ${newLocation.start.href}, ` +
          `cfi ${newLocation.start.cfi}, displayed ${newLocation.start.displayed.page} of ` +
          `${newLocation.start.displayed.total} pages`
      );
      console.log(
        `End index ${newLocation.end.index}, href ${newLocation.end.href}, ` +
          `cfi ${newLocation.end.cfi}, displayed ${newLocation.end.displayed.page} of ` +
          `${newLocation.end.displayed.total} pages`
      );
      if (newLocation.atStart || newLocation.atEnd) {
        console.log(
          `Reading position is at ${
            newLocation.atStart ? "start" : "end"
          } of book.`
        );
      }
    }
    this.setState({
      currentLocation: newLocation,
      currentTocItem: this.state.navigation.get(tocHref),
      currentPrintPage: pageTitle
    });
    // handle CFI local storage
    localStorage.setItem(
      `cfi-${this.props.bookshareId}`,
      JSON.stringify(this.generateLocatorRecord(newLocation.start.cfi))
    );
    // send POST with CFI to server
    this.throttledLocationUpdate(newLocation.start.cfi);
  }

  handleMetadataLoaded(metadata) {
    this.setState({ metadata: metadata });

    // Once the metadata loads, we need to ensure the ViewerSettings voice is set to something valid
    const validVoices = getFilteredVoices(
      this.state.availableVoices,
      [window.navigator.language, metadata.language],
      "en"
    );

    const settings = this.state.viewerSettings;
    if (
      settings.voice === "" ||
      !validVoices.find((v) => v.voiceURI === settings.voice)
    ) {
      // We do silly things in the name of immutability
      const newSettings = Object.assign(new ViewerSettings(), settings);
      newSettings.voice = validVoices[0].voiceURI;
      this.setState({
        viewerSettings: newSettings,
      });
    }

    // update the document title after metadata loads
    document.title = metadata.title
      ? `Bookshare Reader: ${metadata.title}`
      : "Bookshare Reader";
  }

  handleSpineLoaded(spine) {
    this.setState({
      spine: spine,
      bookHasMath: spine.items.some(item => item.properties.includes("mathml")),
     });
  }

  handlePageListLoaded(pageList) {
    this.setState({ pageList: pageList });
  }

  handleNavigationLoaded(navigation, baseUrl) {
    console.log("Navigation loaded");
    console.log(navigation);
    this.setState({
      navigation: navigation,
      navigationBaseUrl: baseUrl,
    });
  }

  handleBookRendered(section, view) {
    // On the very first render, attempt to load bookmarks and highlights
    if (!this._firstRenderCompleted) {
      this._firstRenderCompleted = true;
      this.annotator.initializeBookmarks().then((bookmarks) => {
        this.setState({ bookmarks: bookmarks });
      });
      this.annotator.initializeHighlights().then((highlights) => {
        this.setState({ highlights: highlights });
      });
    }

    gtmEvent({
      event: "pageview",
      page_location: `${(new URL(section.url)).pathname}`,
      page_title: `${section.document.title}`
    });
  }
  /** End EPUB.JS event handlers */

  /** Begin Footer button handlers */
  toggleNavModal() {
    this.setState((prevState) => ({ navOpen: !prevState.navOpen }));
    if (!this.state.navOpen) {
      this.setState({ focusPage: false });
    }
  }

  handleNavLinkClicked(target) {
    this.setState({
      navOpen: false,
      ttsPlaying: false,
    });
    this.handleDisplaySequenceStart();
    this.epubViewerRef.current.rendition.display(target, true);
  }

  toggleSettingsModal() {
    this.setState((prevState) => {
      return { settingsOpen: !prevState.settingsOpen };
    });
  }

  handleSettingsChanged(newSettings) {
    this.setState({ viewerSettings: newSettings });
    // if viewer settings are changed, update the local storage to store them
    localStorage.setItem("viewerSettings", JSON.stringify(newSettings));
  }

  handlePlayButtonClicked() {
    if (this.state.ttsPlaying) {
      this.epubViewerRef.current.ttsInterrupt();
    } else {
      this.epubViewerRef.current.ttsStart();
    }
  }

  handleSkipAheadButtonClicked() {
    this.epubViewerRef.current.ttsSkipAhead();
  }

  handleSkipBackButtonClicked() {
    this.epubViewerRef.current.ttsSkipBack();
  }

  handleBookmarkButtonClicked() {
    // CFI for location
    let cfi = null;
    // Range for text extraction
    let textRange = null;
    // Detailed locator, to be filled later with progression data
    const locator = {};

    if (this.state.ttsPlaying) {
      textRange = this.state.ttsLocation;
      cfi = this.epubViewerRef.current.cfiFromRange(textRange).toString();
    } else {
      // Use currentLocation CFIs to form a Range
      cfi = this.state.currentLocation.start.cfi;
      textRange = this.epubViewerRef.current.rendition
        .getRange(this.state.currentLocation.start.cfi)
        .cloneRange();
      const endRange = this.epubViewerRef.current.rendition.getRange(
        this.state.currentLocation.end.cfi
      );
      textRange.setEnd(endRange.endContainer, endRange.endOffset);
    }

    // Track section index
    // TO-DO: Add progression data
    locator["index"] = this.state.currentLocation.start.index;

    // Only proceed if we have a valid CFI
    if (cfi !== null) {
      const snippetLength = 64;

      let snippet = getTextExcerpt(textRange);

      // Provide default text in case text range doesn't give us usable text.
      snippet = snippet || "Bookmark";

      // If longer than snippet length, append an ellipsis to indicate more text.
      if (snippet?.length > snippetLength) {
        snippet = snippet.substring(0, snippetLength) + "\u2026";
      }
      this.annotator.addBookmark(cfi, snippet, locator);
      this.setState({ bookmarks: this.annotator.listActiveBookmarks() });
    }
  }

  handleDeleteBookmarkButtonClicked(location) {
    if (this.annotator.removeBookmark(location)) {
      this.setState({ bookmarks: this.annotator.listActiveBookmarks() });
    }
  }

  handleHighlightButtonClicked() {
    if (process.env.REACT_APP_READER_FEATURE_HIGHLIGHTS === "enabled") {
      // Grab selection from the view
      if (this.state.textSelection !== null) {
        // Range for text extraction
        let textRange = this.epubViewerRef.current.rendition.getRange(
          this.state.textSelection
        );

        // Detailed locator, to be filled later with progression data
        const locator = {};

        // Track section index
        // TO-DO: Add progression data
        locator["index"] = this.state.currentLocation.start.index;

        this.annotator.addHighlight(
          this.state.textSelection,
          textRange.toString(),
          "#FFFF00",
          null,
          locator
        );
        this.setState({ highlights: this.annotator.listActiveHighlights() });
      }
    }
  }

  handleDeleteHighlightButtonClicked(location) {
    if (this.annotator.removeHighlight(location)) {
      this.setState({ highlights: this.annotator.listActiveHighlights() });
    }
  }
  /**
   * @param {string} selection Selected text, in CFI format.
   */
  handleTextSelectionChanged(selection) {
    this.setState({ textSelection: selection });
  }

  cancelTts() {
    this.epubViewerRef.current.ttsInterrupt();
  }

  /** End Footer button handlers */

  /**
   * Hook for lower-level components to signal change in speech state
   * @param {Boolean} ttsPlaying
   */
  handleSpeechStateChange(ttsPlaying) {
    const newState = { ttsPlaying: ttsPlaying };
    if (ttsPlaying === false) {
      newState.ttsLocation = null;
    }
    this.setState(newState);
  }

  handleSpeechLocationUpdated(newLocation) {
    this.setState({ ttsLocation: newLocation });
  }

  handleErrorRaised(error) {
    this.setState({ error: error, showLoadingSpinner: false });
  }

  handleErrorCleared() {
    this.setState({ error: null });
  }

  handleResetSettingsClicked() {
    this.setState({
      showResetSettingsModal: !this.state.showResetSettingsModal,
    });
  }

  handleDisplaySequenceStart() {
    this.setState({ showLoadingSpinner: true });
  }

  handleDisplaySequenceEnd() {
    this.setState({ showLoadingSpinner: false });
  }

  generateLocatorRecord(newCfi) {
    return {
      titleInstanceId: this.props.bookshareId,
      location: newCfi,
      dateCreated: new Date(),
    };
  }

  /**
   * Sends a new XHR POST to the locationSync path for location storage
   * @param {string} newCfi
   */
  sendLocationUpdate(newCfi) {
    const requestUrl = "/locationSync";
    const requestBody = `bookshareId=${
      this.props.bookshareId
    }&location=${encodeURIComponent(newCfi)}`;
    // fetch is an async call so instead of using 'await' we could just
    // use .then to handle our server responses
    fetch(requestUrl, {
      method: "POST",
      body: requestBody,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).catch(() => {
      console.log(`Error sending location update for location ${newCfi}`);
    });
  }

  returnToBookshare() {
    const baseUrl = window.location.hostname.endsWith(".rnibbookshare.org")
      ? process.env.REACT_APP_RNIB_BASE_URL
      : process.env.REACT_APP_BOOKSHARE_BASE_URL;

    window.location.href = baseUrl + "myBookshare";
  }

  showTableOfContents() {
    this.setState({ navTab: "toc" });
    this.toggleNavModal();
  }

  showListOfBookmarks() {
    this.setState({ navTab: "bookmarks" });
    this.toggleNavModal();
  }

  showPageInput() {
    this.setState({ navTab: "toc", focusPage: true });
    this.toggleNavModal();
  }

  closeNav() {
    this.setState({ navTab: null });
    this.toggleNavModal();
  }

  openWhereAmI() {
    this.setState({ showWhereAmI: !this.state.showWhereAmI });
  }

  handleScrollLeft() {
    // Don't trigger if there's a modal open
    !this.isModalDisplayed() && this.epubViewerRef?.current?.handleArrowPrevClick();
  }

  handleScrollRight() {
    // Don't trigger if there's a modal open
    !this.isModalDisplayed() && this.epubViewerRef?.current?.handleArrowNextClick();
  }

  handleTtsStarted() {
    this.epubViewerRef.current.ttsStart();
  }

  handleTtsStopped() {
    this.epubViewerRef.current.ttsInterrupt();
  }

  toggleAboutModal() {
    this.setState((prevState) => ({ aboutOpen: !prevState.aboutOpen }));
  }

  render() {
    // retrieve the correct language for available voices
    const voiceLanguage =
      this.state.metadata && this.state.metadata.language
        ? this.state.metadata.language
        : window.navigator.language;

    return (
      <Container fluid className="d-flex flex-column px-0" id="fullscreen">
        <div id="statusRegion" aria-live="polite" role="status">
          {this.state.showLoadingSpinner && (
            <div className="loading-spinner">
              <Spinner
                animation="border"
                style={{ color: this.state.viewerSettings.color }}
              >
                <span className="sr-only">Loading</span>
              </Spinner>
            </div>
          )}
          {!this.state.showLoadingSpinner && (
            <span className="sr-only">Loaded</span>
          )}
        </div>
        <Header
          title={this.state.metadata && this.state.metadata.title}
          onSettingsButtonClicked={this.toggleSettingsModal}
          onReturnToBookshareButtonClicked={this.returnToBookshare}
        />
        <Footer
          ttsPlaying={this.state.ttsPlaying}
          currentLocation={this.state.currentLocation}
          currentPrintPage={this.state.currentPrintPage}
          currentTocItem={this.state.currentTocItem}
          spine={this.state.spine}
          navigation={this.state.navigation}
          pageList={this.state.pageList}
          onPlayButtonClicked={this.handlePlayButtonClicked}
          onSkipAheadButtonClicked={this.handleSkipAheadButtonClicked}
          onSkipBackButtonClicked={this.handleSkipBackButtonClicked}
          onBookmarkButtonClicked={this.handleBookmarkButtonClicked}
          showWhereAmI={this.state.showWhereAmI}
          onWhereAmIClicked={this.openWhereAmI}
          onNavButtonClicked={this.toggleNavModal}
        />
        <EpubViewer
          ref={this.epubViewerRef}
          bookshareId={this.props.bookshareId}
          metadata={this.state.metadata}
          ttsPlaying={this.state.ttsPlaying}
          modalOpen={this.isModalDisplayed()}
          voices={this.state.availableVoices}
          viewerSettings={this.state.viewerSettings}
          bookmarks={this.state.bookmarks}
          highlights={this.state.highlights}
          onRenditionRelocated={this.handleRenditionRelocated}
          onMetadataLoaded={this.handleMetadataLoaded}
          onSpineLoaded={this.handleSpineLoaded}
          onPageListLoaded={this.handlePageListLoaded}
          onNavigationLoaded={this.handleNavigationLoaded}
          onBookRendered={this.handleBookRendered}
          onErrorRaised={this.handleErrorRaised}
          onSpeechStateChanged={this.handleSpeechStateChange}
          onSpeechLocationUpdated={this.handleSpeechLocationUpdated}
          onDisplaySequenceStart={this.handleDisplaySequenceStart}
          onDisplaySequenceEnd={this.handleDisplaySequenceEnd}
          onScrollLeft={this.handleScrollLeft}
          onScrollRight={this.handleScrollRight}
          hotkeyManager={this.hotkeyManager}
          onTextSelectionChanged={this.handleTextSelectionChanged}
          bookHasMath={this.state.bookHasMath}
        />
        <NavigationPanel
          show={this.state.navOpen}
          defaultTab={this.state.navTab}
          focusPage={this.state.focusPage}
          navigation={this.state.navigation}
          bookmarks={this.state.bookmarks}
          highlights={this.state.highlights}
          baseUrl={this.state.navigationBaseUrl}
          onNavHide={this.closeNav}
          onNavLinkClicked={this.handleNavLinkClicked}
          onDeleteBookmarkButtonClicked={this.handleDeleteBookmarkButtonClicked}
          onDeleteHighlightButtonClicked={
            this.handleDeleteHighlightButtonClicked
          }
          pageList={this.state.pageList}
          currentTocItem={this.state.currentTocItem}
        />
        <SettingsPanel
          viewerSettings={this.state.viewerSettings}
          show={this.state.settingsOpen}
          onSettingsHide={this.toggleSettingsModal}
          language={voiceLanguage}
          voices={this.state.availableVoices}
          colorLabelCutoff={this.state.colorLabelCutoff}
          onSettingsChanged={this.handleSettingsChanged}
          showResetSettingsModal={this.state.showResetSettingsModal}
          onResetSettingsClicked={this.handleResetSettingsClicked}
          onVoiceSampleRequested={this.cancelTts}
          bookHasMath={this.state.bookHasMath}
        />
        <AboutPanel
          show={this.state.aboutOpen}
          onAboutHide={this.toggleAboutModal}
          metadata={this.state.metadata}
          spine={this.state.spine}
        />
        <ErrorViewer
          show={this.state.error != null}
          error={this.state.error}
          onErrorHide={this.handleErrorCleared}
        />
      </Container>
    );
  }
}

/**
 * Just a shorthand function so checking for the window.dataLayer property isn't repeated throughout.
 * @param {*} details
 */
function gtmEvent(details) {
  if (Array.isArray(window.dataLayer)) {
    window.dataLayer.push(details);
  }
}

export default BookshareReader;
