export default class AutoSortingArray {

    /**
     * @param {Function} comparator Function used for sorting
     */
    constructor(comparator) {
        if (!comparator || typeof comparator !== 'function') {
            throw new Error("Missing required comparator function.");
        }
        this.comparator = comparator;
        this.items = [];
    }

    clear() {
        return this.items.splice(0);
    }

    add(...items) {
        this.items.push(...items);
        this.items.sort(this.comparator);
        return this.items.length;
    }

    /**
     * @param {*} matcher May be an object for identity comparison or a function predicate.
     * @returns Removed element, if found. Otherwise null.
     */
    remove(matcher) {
        const index = (typeof(matcher) !== 'function') ? this.items.indexOf(matcher) : this.items.findIndex(matcher);
        if (index >= 0) {
            return this.items.splice(index, 1)[0];
        } else {
            return null;
        }
    }
}