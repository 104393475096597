import Downshift from 'downshift';
import React, { useRef, useEffect } from "react";
import './ComboBox.css';

function ComboBox(props) {
    const items = props.items; // Expect an array of [{value: ""},...]
    const label = props.label;
    const placeholder = props.placeholder;
    let textInput = useRef(null);

    useEffect(() => {
        if (props.isFocused && textInput.current) {
            textInput.current.focus();
        }
      }, []);

    return (
        <Downshift
            onChange={selection =>
                props.onValueSelected(selection)
            }
            defaultHighlightedIndex={0}
            itemToString={item => (item ? item.value : '')}
        >
            {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                getRootProps,
            }) => (
            <div className="combobox-dropdown">
                <label {...getLabelProps()}>
                    {label}
                    <span className="sr-only">{placeholder}</span>
                </label>
                <div
                    style={{display: 'inline-block'}}
                    {...getRootProps({}, {suppressRefError: true})}
                >
                    <input ref={textInput} placeholder={props.placeholder} {...getInputProps()} />
                </div>
                {isOpen
                    ? 
                    <ul {...getMenuProps()}>
                        {items
                            .filter(item => !inputValue || item.value.includes(inputValue))
                            .slice(0, 10)
                            .map((item, index) => (
                            <li 
                                {...getItemProps({
                                key: item.value,
                                index,
                                item,
                                style: {
                                    backgroundColor:
                                    highlightedIndex === index ? 'lightgray' : 'white',
                                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                                },
                                })}
                            >
                                {item.value}
                            </li>
                        ))}
                    </ul>
                : null}
            </div>
            )}
        </Downshift>
    );
}

export default ComboBox;