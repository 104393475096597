import { SLIDER_FONT_SIZES } from "./enums";

const OVERRIDABLE_PROPERTIES = ['font-family', 'font-size', 'letter-spacing', 'line-height', 
    'color', 'background-color'];

const PERMITTED_FONT_UNITS = ['%', 'em', 'rem'];
const PERMITTED_FONT_SIZE_MATCHER = new RegExp('^[0-9.]+\s*(' + PERMITTED_FONT_UNITS.join('|') + ')$');

// Locate the default font size.
const DEFAULT_FONT_SIZE = SLIDER_FONT_SIZES.find(s => s.default === true).value;

const MATHJAX_CONTEXT_MENU_STYLE_PREFIX = '.CtxtMenu'

/**
 * Translates select ViewerSettings properties into CSS stylesheet rules.
 * @param {ViewerSettings} settings ViewerSettings object
 * @param {*} overrides Selectors that need to be given overrides
 * @returns CSS stylesheet rules
 */
export function cssRulesFromViewerSettings(settings, overrides = {}) {

    // Most of the rules will be applied to the root element
    const rules = {':root': {}};
    const root = rules[':root'];

    // Build prefix matcher if needed. Namespace-aware CSS selectors don't play well
    // with an HTML-parsed document, as HTML does not support namespaces.
    const prefixMatcher = (overrides && overrides['namespace']) ?
        new RegExp('(' + overrides['namespace'].map(ns => ns[0]).join('|') + '){0,1}\\|([A-Za-z0-9\\.]+)', 'g') : null;

    // Before proceeding, make sure font size is not be empty, otherwise it will fall back to the
    // publication setting rather than the enum value with the default flag. Has to happen here
    // first otherwise the following forEach loop will not collect override selectors properly.
    if (settings.useDefaultFontSize || !settings.fontSize) {
        settings.fontSize = DEFAULT_FONT_SIZE;
    }

    OVERRIDABLE_PROPERTIES.forEach(property => {
        const camelCaseProperty = toCamelCase(property);
        if (settings[camelCaseProperty]) {
            // Override the property on the root element
            // EXCEPTION: background-color, which we set on the epub viewer.
            if (property !== 'background-color') {
                root[property] = settings[camelCaseProperty];
            }
            // Use any collected selectors to unset the overridden property
            if (overrides[property]) {
                // If prefix matcher exists, then use it to convert namespace syntax to dumb colon syntax
                const overrideSelector = overrides[property]
                    .map(s => (prefixMatcher) ? s.replaceAll(prefixMatcher, '$1\\:$2') : s)
                    .join(', ');
                rules[overrideSelector] = {};
                rules[overrideSelector][property] = 'unset';    
            }
        }
    });

    if (settings.displayPageNumbers) {
        rules['*[epub\\:type=pagebreak], *[role=doc-pagebreak]'] = {
            'display': 'block',
            'text-align': 'center',
            'text-indent': '0',
            'font-size': nextSmallerSize(settings.fontSize),
            'font-family': settings.fontFamily ? settings.fontFamily : 'initial',
            'font-weight': 'normal',
            'text-decoration': 'initial',
            'width': "15%",
            'margin': '0.25em auto 0.25em auto',
            'border': `thin solid ${settings.textColor}`
        };

        // Page number in title attribute (EPUB style, takes precedence)
        rules['*[epub\\:type=pagebreak][title]::after, *[role=doc-pagebreak][title]::after'] =
            {'content': 'attr(title)', 'display': 'inline-block'};

        // Page number in ARIA label attribute (ARIA style)
        rules['*[epub\\:type=pagebreak][aria-label]::after, *[role=doc-pagebreak][aria-label]::after'] =
            {'content': 'attr(aria-label)', 'display': 'inline-block'};

    } else {
        rules['*[epub\\:type=pagebreak], *[role=doc-pagebreak]'] = {
            'border': '0px',
            'clip': 'rect(0px, 0px, 0px, 0px)',
            'height': '1px',
            'margin': '-1px',
            'overflow': 'hidden',
            'padding': '0px',
            'position': 'absolute',
            'width': '1px'
        };
    }

    // Margin settings are applied to direct descendants of <body>
    let marginRule = "0";
    if (settings.margin === 'medium') {
        marginRule = "0 30px";
    } else if (settings.margin === 'large') {
        marginRule = "0 60px";
    }
    rules['body > *:not(.ttsWordHL):not(.ttsSentenceHL)'] = {'margin' : marginRule};
    return rules;
}

/**
 * Collects selectors for stylesheet rules that contain CSS style properties that may be
 * overridden by user settings. (A future optimization would be to do this by stylesheet
 * rather than by section, because it is likely that CSS files will be referenced by
 * multiple content documents. However, inline style definitions won't have unique
 * identifiers that we can use as a key, unlike URLs of linked CSS.)
 * @param {Document} document
 */
export function collectOverridableSelectors(document) {

    const selectors = {};

    for (const styleSheet of document.styleSheets) {
        for (const rule of styleSheet.cssRules) {
            if (rule.selectorText && !rule.selectorText.startsWith(MATHJAX_CONTEXT_MENU_STYLE_PREFIX) && rule.style) {
                for (const cssProperty of OVERRIDABLE_PROPERTIES) {
                    const value = rule.style[cssProperty];
                    if (value) {
                        // We can keep relative font sizes (ems, rems, percent), but everything else is overridable
                        // and should be collected in the selectors dictionary. Anything that's not a font size is
                        // collected, and font-size declarations that are *not* relative are also collected.
                        if (['font-size'].indexOf(cssProperty) === -1 || !PERMITTED_FONT_SIZE_MATCHER.test(value))
                        {
                            (selectors[cssProperty] = selectors[cssProperty] || []).push(rule.selectorText);
                        }
                    }
                }
            } else if (rule.namespaceURI && rule.prefix) {
                // We're harvesting namespace rules as prefix/URI pairs, instead of straight selector strings
                // like we do for normal rules. We're not using the URIs at this time but we may in future.
                (selectors['namespace'] = selectors['namespace'] || []).push([rule.prefix, rule.namespaceURI]);
            }
        }
    }
    return selectors;
}

/**
 * Removes rules from stylesheet
 * @param {CSSStyleSheet} stylesheet 
 */
export function clearStylesheet(stylesheet) {
    while (stylesheet.cssRules.length > 0) {
        stylesheet.deleteRule(0);
    }
}

/**
 * Injects rules into a stylesheet
 * @param {CSSStyleSheet} stylesheet
 * @param {*} rules
 */
export function injectStylesheetRules(stylesheet, rules) {
    Object.keys(rules).forEach(selector => {
        const declaration = rules[selector];
        const s = Object.keys(declaration).map(property => `${property}: ${declaration[property]}`).join('; ');
        stylesheet.insertRule(`${selector} {${s}}`);
    });
}

/**
 * Takes a dashed string and returns its camel-case equivalent. Used to
 * convert from CSS style property names (e.g. font-family) to JavaScript
 * property names (e.g. fontFamily)
 * @param {String} dashedString 
 * @returns {String} camel-cased string
 */
function toCamelCase(dashedString) {
    return (dashedString.split('-')).map((s, i) => (i > 0 ? s[0].toUpperCase() + s.substring(1): s)).join('');
}

function nextSmallerSize(fontSize) {
    let i = SLIDER_FONT_SIZES.findIndex(s => s.value === fontSize);
    if (i === -1) {
        // No matching font size, use the next size down from the default
        i = SLIDER_FONT_SIZES.findIndex(s => s.default === true) - 1;
    } else if (i > 0) {
        // Anything higher than zero gets bumped down one size
        i -= 1;
    }
    return SLIDER_FONT_SIZES[i].value;
}

/**
 * Calculate luminance. Based on simple luma formula at
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_Colors_and_Luminance#alternate_srgb_to_y_simple
 * @param {string} color Hex color
 */
export function luma(color) {

    // Get the components
    let match = color.match(/#?([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})/);

    // If no match, try again with the 3-digit version
    if (!match) {
        match = color.match(/#?([0-9A-Fa-f]{1})([0-9A-Fa-f]{1})([0-9A-Fa-f]{1})/);
    }

    // If still no match, bail
    if (!match) {
        return 0;
    }

    // Get the 8-bit RGB values.
    const red = parseInt((match[1].length === 2) ? match[1] : match[1] + match[1], 16);
    const green = parseInt((match[2].length === 2) ? match[2] : match[2] + match[2], 16);
    const blue = parseInt((match[3].length === 2) ? match[3] : match[3] + match[3], 16);

    return (0.2126 * Math.pow(red/255, 2.2))
        + (0.7152 * Math.pow(green/255, 2.2))
        + (0.0722 * Math.pow(blue/255, 2.2));
}

/**
 * Returns the luminance difference of color1 and color2.
 * @param {string} color1
 * @param {string} color2
 */
export function deltaLuma(color1, color2) {
    return luma(color1) - luma(color2);
}
