import React from 'react';

class ColorPresetOption extends React.Component {

    render() {
        const label = document.documentElement.clientWidth >= this.props.colorLabelCutoff ? this.props.colorPreset.label : this.props.colorPreset.shortened;
        const colorAriaLabel=`Color Theme, ${label}`
        const isPresetSelected = this.props.colorPreset.value === this.props.selectedColorPreset;

        return (
            <button id={this.props.colorPreset.value} 
                className={`color-preview-button ${isPresetSelected ? 'selected' : ''}`}
                onClick={this.props.onClick}
                aria-label={colorAriaLabel}
                aria-pressed={isPresetSelected}
                type="button"
            >
                <div>
                    <div  
                        className={this.props.colorPreset.value}  
                        aria-hidden="true"
                    >
                        <p>The quick brown <span>fox <span>jumped</span> over</span> the lazy dog.</p>
                        <p>Peter Piper picked a peck of pickled peppers.</p>
                    </div>
                    <p className="color-preview-label">{label}</p>
                </div>
            </button>
        )
    }
}

export default ColorPresetOption;