import React from 'react';
import {Modal} from 'react-bootstrap';

export default function AboutPanel(props) {

    const version = process.env.REACT_APP_BOOKSHARE_READER_VERSION;
    const commitHash = process.env.REACT_APP_BOOKSHARE_READER_COMMIT_HASH;
    const versionString = version + (commitHash ? `#${commitHash}` : '');

    let metadata = [];
    if (props.metadata) {
        for (let key in props.metadata) {
            if (props.metadata[key]) {
                metadata.push(<tr key={key}><td><strong>{key}</strong></td><td>{props.metadata[key]}</td></tr>);
            }
        }    
    }

    let spineItems = [];
    if (props.spine) {
        for (let i = 0; i < props.spine.length; i++) {
            const s = props.spine.spineItems[i];
            spineItems.push(
                <tr key={i}>
                    <td>{s.idref}</td>
                    <td><nobr>{s.href}</nobr></td>
                    <td>{s.linear ? 'Y' : 'N'}</td>
                    <td>{s.properties.join((<br/>))}</td>
                </tr>);
        }    
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onAboutHide}
            aria-labelledby="aboutModalLabel"
            scrollable={true}
            >
            <Modal.Header closeButton>
                <Modal.Title id="aboutModalLabel">About Bookshare Web Reader</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p class="h5">Bookshare Web Reader Version</p>
                <p>{versionString}</p>
                <p class="h5">Title Metadata</p>
                <p>
                    <table>
                        {metadata}
                    </table>
                </p>
                <p class="h5">Spine items</p>
                <p>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>HREF</th>
                                <th>Linear</th>
                                <th>Properties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {spineItems}
                        </tbody>
                    </table>
                </p>
            </Modal.Body>
        </Modal>
    );
}