import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

function BookmarksTab(props) {

    const bookmarks = [];

    for (let i = 0; i < props.bookmarks.length; i++) {
        bookmarks.push(
            <Row key={props.bookmarks[i].location}>
                <Col style={{overflowWrap: 'anywhere'}}>
                    <a href="#"
                        data-cfi={props.bookmarks[i].location}
                        onClick={props.onBookmarkLinkClicked}>{props.bookmarks[i].text}</a>
                </Col>
                <Col xs={2}>
                    <Button
                        variant="link"
                        onClick={() => props.onDeleteBookmarkButtonClicked(props.bookmarks[i].location)}
                        aria-label="Delete bookmark">
                        <FontAwesomeIcon icon={faTrashAlt} size="1x" role="presentation"/>
                    </Button>
                </Col>
            </Row>
        );
    }

    return (
        <Container>
            <>{(bookmarks.length > 0) ? bookmarks : 'No Bookmarks'}</>
        </Container>
    );
}

export default BookmarksTab;