import React from 'react';
import {Modal} from 'react-bootstrap';
import './ErrorViewer.css'

function ErrorViewer(props) {
    let title = "Error Loading Book";
    let message, link, linkMessage = "";

    switch(props?.error?.status) {
        case 401: 
            title = 'Log in required';
            message = 'You must log in to access the Bookshare Reader.';
            link = process.env.REACT_APP_BOOKSHARE_BASE_URL + 'login';
            linkMessage = 'Log in to Bookshare'
            break;
        case 403: 
            title = 'Access denied';
            message = 'You do not have permission to view this title.';
            link = process.env.REACT_APP_BOOKSHARE_BASE_URL + 'myBookshare';
            linkMessage = 'Return to My Bookshare'
            break;
        case 404: 
            title = 'Title not found';
            message = 'The requested title could not be found';
            link = process.env.REACT_APP_BOOKSHARE_BASE_URL + 'myBookshare';
            linkMessage = 'Return to My Bookshare'
            break;
        default:
            title = 'There was a problem';
            message = 'Something went wrong. Try again later. If issues persist please contact customer support.';
            link = process.env.REACT_APP_BOOKSHARE_BASE_URL + 'contactUs';
            linkMessage = 'Contact Customer Support'
    }

    return (
        <Modal 
            className="errorViewer" 
            centered 
            show={props.show}
            backdrop="static"
            aria-labelledby="errorModalLabel"
            >
            <Modal.Header>
                <Modal.Title id="errorModalLabel">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {message}
                </p>
                <a href={link}>{linkMessage}</a>
            </Modal.Body>
        </Modal>
    );
}

export default ErrorViewer;