import { EpubCFI } from "epubjs";
import { patchRange } from "./CfiUtils";

export default function patchRendition(rendition) {
    // Using double-underscore function names because epub.js already uses single-underscore
    // function names to indicate "private" functions

    const focusTarget = (target) => {
        const contents = rendition.getContents()[0];
        if (contents) {
            let targetElement = null;
            if (rendition.epubcfi.isCfiString(target)) {
                // Find the CFI target's initial container.
                const cfi = new EpubCFI(target);
                const container = cfi.toRange(contents.document).startContainer;
                targetElement = (container.nodeType === Node.ELEMENT_NODE) ? container : container.parentElement;
            } else if (typeof target === 'string') {
                // If it has an ID, use that, else just get the first item in the doc
                const fragments = target.split('#');
                if (fragments.length === 2) {
                    targetElement = contents.document.getElementById(fragments[1]);
                } else {
                    targetElement = contents.document.body.firstElementChild;
                }
            }

            if (targetElement) {
                targetElement.setAttribute('tabindex', -1);
                targetElement.focus({preventScroll: true});
            }
        }
    };

    // Simple wrapper on display to emit a displayRequested event.
    // Optionally, set a flag to tell the rendition to focus on the rendered location.
    rendition.__display = rendition.display;
    rendition.display = function(target, focusOnTarget=false) {
        this.emit('displayRequested', target);
        return this.__display(target).then(() => focusOnTarget && focusTarget(target));
    }

    // Simple wrapper on resize to emit a resizeRequested event.
    rendition.__resize = rendition.resize;
    rendition.resize = function(width, height, epubcfi) {
        this.emit('resizeRequested');
        return this.__resize(width, height, epubcfi);
    }

    // next() and prev() are  complex -- they delegate to the view manager and depending on the context,
    // may append a whole new section or just scroll a column of text into view. Consequently, we also
    // fire a completion event after epub.js has done its work.
    rendition.__next = rendition.next;
    rendition.next = function() {
        this.emit('nextRequested');
        return this.__next()
            .then(() => this.emit('nextCompleted'))
            .catch((e) => this.emit('nextError', e));
    }

    rendition.__prev = rendition.prev;
    rendition.prev = function() {
        this.emit('prevRequested');
        return this.__prev()
            .then(() => this.emit('prevCompleted'))
            .catch((e) => this.emit('prevError', e));
    }

    // Patch the getRange function so it can properly handle CFIs with non-text node anchors
    rendition.__getRange = rendition.getRange;
    rendition.getRange = function(cfi, ignoreClass) {
        return patchRange(this.__getRange(cfi, ignoreClass));
    }

}