import Bookmark from "./Bookmark";
import Highlight from "./Highlight";

const BOOKMARKS = 'bookmarks';
const HIGHLIGHTS = 'highlights';

export default class AnnotationStorageProvider {

    /**
     * @param {*} store Key-value store. Uses localStorage by default.
     */
    constructor(store) {
        this.store = store || window.localStorage;
    }

    /**
     * Serializes bookmarks into a JSON string and saves them to storage.
     * @param {number} bookId 
     * @param {Bookmark[]} bookmarks 
     */
    storeBookmarks(bookId, bookmarks) {
        if (bookmarks.length > 0) {
            this.store.setItem(this._storageKey(bookId, BOOKMARKS), JSON.stringify(bookmarks));
        } else {
            this.store.removeItem(this._storageKey(bookId, BOOKMARKS));
        }
    }

    /**
     * Retrieves and parses bookmark JSON from storage.
     * @param {number} bookId 
     * @returns {Bookmark[]}
     */
    loadBookmarks(bookId) {
        const bookmarks = [];
        const data = JSON.parse(this.store.getItem(this._storageKey(bookId, BOOKMARKS)));
        if (data && Array.isArray(data)) {
            for (const json of data) {
                bookmarks.push(Bookmark.fromJson(json));
            }
        }
        return bookmarks;
    }

    /**
     * Serializes highlights into a JSON string and saves them to storage.
     * @param {number} bookId 
     * @param {Highlight[]} highlights
     */
    storeHighlights(bookId, highlights) {
        if (highlights.length > 0) {
            this.store.setItem(this._storageKey(bookId, HIGHLIGHTS), JSON.stringify(highlights));
        } else {
            this.store.removeItem(this._storageKey(bookId, HIGHLIGHTS));
        }
    }

    /**
     * Retrieves and parses highlight JSON from storage.
     * @param {number} bookId 
     * @returns {Highlight[]}
     */
    loadHighlights(bookId) {
        const highlights = [];
        const data = JSON.parse(this.store.getItem(this._storageKey(bookId, HIGHLIGHTS)));
        if (data && Array.isArray(data)) {
            for (const json of data) {
                highlights.push(Highlight.fromJson(json));
            }
        }
        return highlights;
    }

    _storageKey(bookId, type) {
        return `${type}-${bookId}`;
    }

};