import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {COLORS} from '../utils/enums';
import ColorDropdown from './ColorDropdown';
import ColorsPreviewContainer from './ColorsPreviewContainer';

class ColorTab extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const backgroundColorLabel = "backgroundColorLabel";
        const sentenceHighlightColorLabel = "sentenceHighlightColorLabel";
        const textColorLabel = "textColorLabel";
        const wordHighlightColorLabel = "wordHighlightColorLabel";

        return (
            <>
            <Row>
                <Col xs={12} sm={3}>
                    <div className="setting-header">
                        <strong>Color Presets</strong>
                    </div>
                </Col>
                <ColorsPreviewContainer
                    colorLabelCutoff={this.props.colorLabelCutoff}
                    colorPreset={this.props.colorPreset}
                    viewerSettings={this.props.viewerSettings}
                    onClick={this.props.onPresetColorChange}
                />
            </Row>
            <Row>
                <Col xs={12} sm={3}>
                    <div className="setting-header">
                        <strong>Customize</strong>
                    </div>
                </Col>
            </Row>
            <Row className="color-row">
                <Col xs={6} sm={3}><strong id={backgroundColorLabel}>Background Color</strong></Col>
                <Col xs={6} sm={3}>
                    <ColorDropdown
                        id="backgroundColorTogggle"
                        options={COLORS}
                        dropDownLabel={backgroundColorLabel}
                        onSelect={(value) => this.props.onCustomColorSelect('backgroundColor', value)}
                        stateValue={this.props.viewerSettings.backgroundColor}
                    />
                </Col>
                <Col xs={6} sm={3}><strong id={sentenceHighlightColorLabel}>Sentence Highlight</strong></Col>
                <Col xs={6} sm={3}>
                    <ColorDropdown
                        id="sentenceHighlightColorToggle"
                        options={COLORS}
                        dropDownLabel={sentenceHighlightColorLabel}
                        onSelect={(value) => this.props.onCustomColorSelect('sentenceHighlightColor', value)}
                        stateValue={this.props.viewerSettings.sentenceHighlightColor}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6} sm={3}><strong id={textColorLabel}>Text Color</strong></Col>
                <Col xs={6} sm={3}>
                    <ColorDropdown
                        id="textColorToggle"
                        options={COLORS}
                        dropDownLabel={textColorLabel}
                        onSelect={(value) => this.props.onCustomColorSelect('textColor', value)}
                        stateValue={this.props.viewerSettings.textColor}
                    />
                </Col>
                <Col xs={6} sm={3}><strong id={wordHighlightColorLabel}>Word Highlight</strong></Col>
                <Col xs={6} sm={3}>
                    <ColorDropdown
                        id="wordHighlightColorToggle"
                        options={COLORS}
                        dropDownLabel={wordHighlightColorLabel}
                        onSelect={(value) => this.props.onCustomColorSelect('wordHighlightColor', value)}
                        stateValue={this.props.viewerSettings.wordHighlightColor}
                    />
                </Col>
            </Row>
            </>
        );
    }
}

export default ColorTab;