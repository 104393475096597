import { FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";

function RadioSet(props) {
  const radioButtons = [];

  /**
   * Compares a provided value against a reference value to decide whether
   * the radio box should be checked. Handles primitives (strings, numbers)
   * via the triple-equals operator. Handles objects by comparing each of
   * the properties in the referenceValue to the provided value.
   * @param {*} value
   * @param {*} referenceValue
   * @returns
   */
  const isChecked = function (value, referenceValue) {
    if (typeof value === "object" && typeof referenceValue === "object") {
      for (const k in referenceValue) {
        if (value[k] !== referenceValue[k]) {
          return false;
        }
      }
      return true;
    } else {
      return value === referenceValue;
    }
  };

  for (const option of props.options) {
    // Options consist of:
    // * value -- radio button value
    // * label -- label text
    // * style -- optional style object to apply to label
    // * id -- optional id string to use for component keys/ids if value is an object
    const idKey = option.id ? option.id : option.value;

    // Firefox a11y inspector does not like whitespace in label[for] or aria-labelled-by IDs
    const id = props.name + "_" + idKey.replace(/\s/, "_");
    radioButtons.push(
      <FormCheck key={idKey}>
        <FormCheck.Input
          type="radio"
          name={props.name}
          id={id}
          onChange={(e) => props.onChange(e.target.value)}
          value={idKey}
          checked={isChecked(props.value, option.value)}
          disabled={props.disabled}
        />
        <FormCheck.Label
          htmlFor={id}
          style={option.style || null}
          disabled={props.disabled}
        >
          {option.label}
        </FormCheck.Label>
      </FormCheck>
    );
  }

  const radioGroup = (
    <div
      className="radio-group"
      role="radiogroup"
      aria-labelledby={props.radioGroupLabel}
    >
      {radioButtons}
    </div>
  );

  return props.disabled ? (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      placement={"right"}
      overlay={(overlayProps) => (
        <Tooltip {...overlayProps}>{props.disabledMessage}</Tooltip>
      )}
    >
      {radioGroup}
    </OverlayTrigger>
  ) : (
    radioGroup
  );
}

export default RadioSet;
