import React from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton'
import { Row, Col, Button } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus, faBook} from '@fortawesome/free-solid-svg-icons';
import './CustomSlider.css'

function CustomSlider(props) {
    const defaultIndex = props.options.findIndex(x => x.default === true);
    const thumbValue = props.options.findIndex(x => x.value === props.value);
    const defaultSelected = defaultIndex === thumbValue;

    const setToDefault = (e) => {
        e.preventDefault();
        props.onChange(props.options[defaultIndex].value);
    };

    const handleDecrement = () => {
        if (thumbValue > 0) {
            props.onChange(props.options[thumbValue - 1].value)
        }
    };

    const handleIncrement = () => {
        if (thumbValue < props.options.length - 1) {
            props.onChange(props.options[thumbValue + 1].value)
        }
    };

    const handleSliderChange = (evt) => {
        props.onChange(props.options[evt.target.valueAsNumber].value);
    };

    return (
        <Row className="custom-slider">
            <Col xs={12} sm={3}>
                <div className="setting-header">
                    <label htmlFor={props.name}><strong>{props.label}</strong></label>
                </div>
            </Col>
            <Col xs={12} sm={9}>
                <Row className="button-container">
                    <Col xs={7}>
                        <Button 
                            className="slider-button" 
                            variant="outline-dark"
                            disabled={thumbValue <= 0}
                            onClick={handleDecrement}
                            aria-label={props.decrementButtonLabel || "Decrease"}
                        >
                            <FontAwesomeIcon icon={props.decrementButton || faMinus} />
                        </Button>
                        <Button 
                            className="slider-button" 
                            variant="outline-dark"
                            disabled={thumbValue >= props.options.length - 1}
                            onClick={handleIncrement}
                            aria-label={props.incrementButtonLabel || "Increase"}
                        >
                            <FontAwesomeIcon icon={props.incrementButton || faPlus} />                    
                        </Button> 
                        <Button
                            className={`${defaultSelected ? 'default-selected' : ''} slider-button`}
                            variant="outline-dark" 
                            onClick={setToDefault}
                            aria-label={props.defaultButtonLabel || "Set to Default"}
                        >
                            <FontAwesomeIcon icon={props.defaultButton || faBook} />
                        </Button>
                    </Col>
                    <Col xs={5} className="slider-value">
                        {props.options[thumbValue].label}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <input
                            id={props.name}
                            value={thumbValue}
                            type="range"
                            min={0}
                            max={props.options.length - 1}
                            step={1}
                            onChange={handleSliderChange}
                            aria-label={`${props.label}`}
                            aria-valuemin={0}
                            aria-valuemax={props.options.length - 1}
                            aria-valuenow={thumbValue}
                            aria-valuetext={props.options[thumbValue].label}
                        ></input>
                    </Col>
                </Row>
            </Col>
        </Row>);
}

export default CustomSlider;