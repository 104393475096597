import React from 'react';
import {Col, Row, Dropdown} from 'react-bootstrap';
import RadioSet from './RadioSet';
import CustomSlider from "./CustomSlider";
import {FONT_FAMILIES, LETTER_SPACINGS, SLIDER_FONT_SIZES} from '../utils/enums';

class TextTab extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const fontOptions = FONT_FAMILIES.map(f => {
            return (
                <Dropdown.Item key={f.value} eventKey={f.value} style={{'fontFamily': f.value}}>
                    {f.label}
                </Dropdown.Item>
            );
        });
        
        const fontStyleLabel = "fontStyleLabel";
        const characterSpacingRadioLabel = "characterSpacingRadioLabel";

        return (
            <>
            <Row>
                <Col>
                    <CustomSlider 
                        name="textSize"
                        label="Text Size"
                        value={this.props.viewerSettings.fontSize}
                        options={SLIDER_FONT_SIZES}
                        onChange={this.props.onFontSizeChange}
                        incrementButtonLabel={"Increase Font Size"}
                        decrementButtonLabel={"Decrease Font Size"}
                        defaultButtonLabel={"Set to 14pt"}
                />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={3}>
                    <div className="setting-header">
                        <strong id={fontStyleLabel}>Font Style</strong>
                    </div>
                </Col>
                <Col xs={12} sm={9}>
                    <Dropdown onSelect={this.props.onFontFamilyChange}>
                        <Dropdown.Toggle id="fontStyleToggle" className="text-dropdown-button" aria-labelledby={fontStyleLabel}>
                            {FONT_FAMILIES.find(f => f.value === this.props.viewerSettings.fontFamily).label}
                        </Dropdown.Toggle>                                            
                        <Dropdown.Menu>
                            {fontOptions}
                        </Dropdown.Menu>
                    </Dropdown>
                    <br/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={3}>
                    <div className="setting-header">
                        <strong id={characterSpacingRadioLabel}>Character Spacing</strong>
                    </div>
                </Col>
                <Col xs={12} sm={9}>
                    <RadioSet
                        name="letterSpacing"
                        options={LETTER_SPACINGS}
                        value={this.props.viewerSettings.letterSpacing}
                        radioGroupLabel={characterSpacingRadioLabel}
                        onChange={this.props.onLetterSpacingChange}
                    />
                    <br/>
                </Col>
            </Row>
            </>
        );
    }
}

export default TextTab;