import { Row, Col } from 'react-bootstrap';
import { COLOR_PREVIEW_NAMES } from '../utils/enums';
import './ColorPreviewContainer.css';
import ColorPresetOption from './ColorPresetOption';

function ColorsPreviewContainer(props) {

    const colorPreviewMidpoint = Math.ceil(COLOR_PREVIEW_NAMES.length / 2);

    /*
    Create an array of buttons representing color presets
    */
    const getRow = function (array, start, end) {
        const slicedArray = array.slice(start, end);
        const row = slicedArray.map(f => {
            return (
                <Col key={f.value}>
                    <ColorPresetOption
                        colorPreset={f}
                        selectedColorPreset={props.colorPreset}
                        onClick={props.onClick}
                    />
                </Col>
            );
        });
        return (<Row>{row}</Row>);
    }

    const firstRow = getRow(COLOR_PREVIEW_NAMES, 0, colorPreviewMidpoint);
    const secondRow = getRow(COLOR_PREVIEW_NAMES, colorPreviewMidpoint, COLOR_PREVIEW_NAMES.length);

    return (
        <Col xs={10}>
            {firstRow}
            {secondRow}
        </Col>
    );
}

export default ColorsPreviewContainer;
