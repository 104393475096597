import { cfiRangeFromCfiPair } from "../CfiUtils";
import { eq } from "../CommonUtils";

export default class Highlight {

    /**
     * @param {number} bookId Bookshare book ID
     * @param {string} location Range of highlight, in CFI
     * @param {string} text Text excerpt
     * @param {string} color Highlight color in hex format
     * @param {string} note User-entered note
     * @param {*} locator Readium-compatible locator
     * @param {string} dateUpdated Date as an ISO string
     * @param {boolean} synced Indicates whether bookmark has been saved with a remote service.
     * @param {boolean} synced Indicates whether the bookmark has been marked for deletion.
     */
    constructor(bookId, location, text, color, note, locator = {},
                    dateUpdated = null, synced = false, shouldDelete = false) {
        /** @type {Number} Bookshare title instance ID */
        this.bookId = bookId;

        /** @type {string} Location of highlight, expressed as a CFI range */
        this.location = location;

        /** @type {string} Textual content of Bookmark */
        this.text = text;

        /** @type {string} Highlight color in hex format */
        this.color = color;

        /** @type {string} User-entered note */
        this.note = note;
        
        /** @type {object} Additional locator data */
        this.locator = locator;

        /** @type {boolean} True if previously synced to the server */
        this.synced = synced;

        /** @type {boolean} True if bookmark is pending server deletion */
        this.shouldDelete = shouldDelete;

        /** @type {Date} Date created */
        const dateInMilliseconds = Date.parse(dateUpdated);
        this.dateUpdated = isNaN(dateInMilliseconds) ? new Date() : new Date(dateInMilliseconds);
    }

    equals(other) {
        return (
            this.bookId === other.bookId
                && this.location === other.location
                && this.text === other.text
                && this.color === other.color
                && this.note === other.note
                && eq(this.locator, other.locator)
                && this.synced === other.synced
                && this.shouldDelete === other.shouldDelete
                && this.dateUpdated.getTime() === other.dateUpdated.getTime()
        );
    }

    /**
     * Creates a new Highlight from a JSON string.
     * @param {string} jsonString
     */
    static fromJsonString(jsonString) {
        return Highlight.fromJson(JSON.parse(jsonString));
    }

    /**
     * Creates a new Highlight from a JSON object.
     * @param {*} json
     */
     static fromJson(json) {
        // If we have start/end location attributes, generate a range CFI
        const location = (json.hasOwnProperty('startLocation') && json.hasOwnProperty('endLocation'))
                            ? cfiRangeFromCfiPair(json.startLocation, json.endLocation) : json.location;

        return new Highlight(json.bookId || json.bookshareId, location, json.text, json.color, json.note,
            json.locator, json.dateUpdated, json.synced, json.shouldDelete);
    }

}
